import api from "./api";

/* This class is used to handle a service alerts to process of schedule */
class ServicePreparationsService {
  /**
   * `detail(id)` returns the service prepatarions  with the given id
   * @param id - The ID of the service to retrieve.
   * @returns The service with the given id.
   */
  async detail(id) {
    return await api.get("/api/servicepreparation/" + id);
  }
  /* This function is used to get all service preparations from the server. */
  async get(filter) {
    return await api.get("/api/servicepreparation/", filter);
  }
}
export default new ServicePreparationsService();
