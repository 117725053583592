import api from "./api";

/* This class is used to handle a patient care path for a patient */
class PatientCarePathService {
  /**
   * `detail(id)` returns the patient care path with the given id
   * @param id - The ID of the patient care path to retrieve.
   * @returns The patient care path with the given id.
   */
  detail(id) {
    return api.get("/api/patientcarepath/" + id);
  }

  /* This function is used to get all patient care paths from the server. */
  get(filter) {
    return api.get("/api/patientcarepath/", filter);
  }

  /**
   * The function "checkMultiService" makes an API call to retrieve user care paths for multiple
   * services based on a given filter.
   * @param filter - The "filter" parameter is an object that contains the criteria for filtering the
   * user care path multiple services. It is used to specify the conditions that the returned services
   * must meet.
   * @returns the result of the API call to "/api/userCarePathMultipleServices" with the provided
   * filter.
   */
  checkMultiService(id) {
    return api.get("/api/userCarePathMultipleServices/" + id);
  }
}
export default new PatientCarePathService();
