/* Exporting patient process state default object. */
export default {
  patientCarePathService: null,
  insurerData: null,
  planData: null,
  serviceData: null,
  servicePlanData: null,
  serviceAlertsData: null,
  servicePreparations: null,
  serviceConditions: null,
  servicePlanConditions: null,
  appointmentAdditional: null,
  medicalOrderData: null,
  doctorData: null,
  entityID: null,
  doctorID: null,
  dateAppointment: null,
  hourAppointment: null,
  phase: null,
  extraData: null,
};
