import PatientCarePathServiceService from "@/services/patient-care-path-service-service";

/* This is a JavaScript object that is being exported to the Vuex store. */
export default {
  /**
   * This method get patient care path.
   * The `async` keyword is used to indicate that the function is asynchronous.
   * @param model - The model that is being passed to the API.
   * @returns The data is being returned as a promise.
   */
  async getPatientCarePathServices({ commit }, model) {
    return await PatientCarePathServiceService.get({ params: model }).then(
      (result) => {
        /* This is a mutation that is being called in the JavaScript file. */
        if (result.data.data && result.data.data.length > 0) {
          var data = { data: result.data.data, carePathID: model.carePathID };
          commit("CAREPATH_SERVICES_SUCCESS", data);
        }
        return Promise.resolve(result.data);
      },
      (error) => {
        commit("CAREPATH_SERVICES_FAILURE");
        return Promise.reject(error);
      }
    );
  },
  /* This method clean patient care path service data. */
  clean({ commit }) {
    commit("CAREPATH_SERVICES_CLEAN");
  },
};
