var customTextRules = {
  size: [
    {
      message: "Ingrese un tamaño válido",
      trigger: "blur",
      required: false,
    },
  ],
  text: [
    {
      required: true,
      message: "Ingrese un texto",
      trigger: "blur",
    },
  ],
  color: [
    {
      max: 7,
      message: "El código no puede tener mas de 6 caracteres",
      trigger: "blur",
    },
    {
      trigger: "blur",
      pattern: /^#[0-9A-Fa-f]{3,6}/,
      message: "Ingrese un código de color válido",
    },
  ],
};
var customImageRules = {
  size: [
    {
      message: "Seleccione una imagen",
      trigger: "blur",
      required: true,
    },
  ],
};

const rules = { customTextRules, customImageRules };

export default rules;
