import DocumentTypeService from "@/services/document-type-service.js";
import AffiliationTypeService from "@/services/affiliation-type-service";
import CountryService from "@/services/country-service";
import DepartmentService from "@/services/department-service";
import MaritalStatusService from "@/services/marital-status-service";
import PatientTypeService from "@/services/patient-type-service";
import SocialClassService from "@/services/social-class-services";
import TermPolicyService from "@/services/term-policy-service";
import GeneralService from "@/services/general-service.js";
import { notification } from "ant-design-vue";
import InsurerService from "@/services/insurer-service.js";
import InsurerPlanService from "@/services/insurer-plan-service.js";
import CitiesService from "@/services/cities-service.js";
import EntitiesService from "@/services/entity-service.js";
import DoctorService from "@/services/doctor-service.js";
import ScheduleService from "@/services/schedule-service.js";
import DoctorSpecialtyService from "@/services/doctor-specialty-service.js";
import PatientRecoverPasswordService from "@/services/patient-recover-password-service.js";
import SystemVariableService from "@/services/system-variable-service.js";
import MedicalOrderService from "@/services/medical-order-service.js";
import ServicePlanService from "@/services/service-plan-service.js";
import CustomTextService from "@/services/custom-text-service.js";
import AppointmentService from "@/services/appointment-service";
import CustomImageService from "@/services/custom-image-service.js";
import userValidationsService from "../services/user-validations-service";
import embebedService from "../services/embebed-service";
import PatientAcceptanceService from "@/services/patient-acceptance-service.js";
import Dayjs from "dayjs";
/* Creating a new object functions of use common. */
const commonFunctions = {
  /* Calling a service to get the document types. */
  async getDocumentTypes() {
    var options = [];

    options = await DocumentTypeService.get()
      .then((result) => {
        var data = [];
        if (result != null && result.data.data.length > 0) {
          data = this.processCommonData(
            result.data.data,
            "documentTypeID",
            "name"
          );
        }
        return data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return options;
  },
  /* Calling a service to get the affiliation types. */
  async getAffilitationTypes() {
    var options = [];

    options = await AffiliationTypeService.get()
      .then((result) => {
        var data = [];
        if (result != null && result.data.data.length > 0) {
          data = this.processCommonData(
            result.data.data,
            "affiliationTypeID",
            "name"
          );
        }
        return data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return options;
  },
  /* Calling a service to get countries. */
  async getCountries() {
    var options = [];

    options = await CountryService.get()
      .then((result) => {
        var data = [];
        if (result != null && result.data.data.length > 0) {
          data = this.processCommonData(result.data.data, "countryID", "name");
        }
        return data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return options;
  },
  /* Calling a service to get departments. */
  async getDepartments() {
    var options = [];

    options = await DepartmentService.get()
      .then((result) => {
        var data = [];
        if (result != null && result.data.data.length > 0) {
          data = this.processCommonData(
            result.data.data,
            "departmentID",
            "name"
          );
        }
        return data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return options;
  },
  /* Calling a service to get marital status. */
  async getMaritalStatus() {
    var options = [];

    options = await MaritalStatusService.get()
      .then((result) => {
        var data = [];
        if (result != null && result.data.data.length > 0) {
          data = this.processCommonData(
            result.data.data,
            "maritalStatusID",
            "name"
          );
        }
        return data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return options;
  },
  /* Calling a service to get patient types. */
  async getPatientTypes() {
    var options = [];

    options = await PatientTypeService.get()
      .then((result) => {
        var data = [];
        if (result != null && result.data.data.length > 0) {
          data = this.processCommonData(
            result.data.data,
            "patientTypeID",
            "name"
          );
        }
        return data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return options;
  },
  /* Calling a service to get social class. */
  async getSocialClass() {
    var options = [];

    options = await SocialClassService.get()
      .then((result) => {
        var data = [];
        if (result != null && result.data.data.length > 0) {
          data = this.processCommonData(
            result.data.data,
            "socialClassID",
            "name"
          );
        }
        return data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return options;
  },
  /* Calling a service to get term policies. */
  async getTermPolicies(filter) {
    var options = [];
    options = await TermPolicyService.get(filter)
      .then((result) => {
        return result.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return options;
  },
  /* Calling a service to get insurers. */
  async getInsurers(InsurerName) {
    var options = [];

    var filter = {
      page: 0,
      size: 50,
      orderBy: "orderlinessASC",
      name: InsurerName,
    };

    options = await InsurerService.get(filter)
      .then((result) => {
        var data = [];
        if (result != null && result.data.data.length > 0) {
          data = this.processCommonData(result.data.data, "insurerID", "name");
        }
        return data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return options;
  },
  /* Calling a service to get genre types. */
  async getGenres() {
    var options = [
      {
        value: "F",
        label: "Femenino",
      },
      {
        value: "M",
        label: "Masculino",
      },
    ];

    return options;
  },
  /* Calling a service to get plans by insurer id. */
  async getPlanByInsurerID(InsurerID) {
    var options = [];

    var filter = {
      page: 0,
      size: 50,
      insurerID: InsurerID,
      orderBy: "nameASC",
    };

    options = await InsurerPlanService.get(filter)
      .then((result) => {
        var data = [];
        if (result != null && result.data.data.length > 0) {
          data = this.processCommonData(result.data.data, "planID", "name");
        }
        return data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return options;
  },
  /* get information of plan by id */
  async getPlanByID(planID) {
    var response = [];
    response = await InsurerPlanService.detail(planID)
      .then((result) => {
        return result.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return response;
  },
  /* Calling a service to get cities by department id. */
  async getCitiesByDepartmentID(filter, tempData) {
    var value = await this.loadCities(filter, tempData);

    if (value.next) {
      value = await this.getCitiesByDepartmentID(filter, tempData);
    } else {
      return true;
    }
  },
  /**
   * It calls the citiesService.get() function and returns the result.
   * @param filter - The filter object that is used to filter the data.
   * @param tempData - This is the data that is being loaded.
   * @returns The `loadCities` function returns a `Promise` object. The `Promise` object is a
   * JavaScript object that represents the eventual completion or failure of an asynchronous operation.
   */
  async loadCities(filter, tempData) {
    return await CitiesService.get(filter)
      .then((result) => {
        if (result.data.pagingInfo.hasNextPage) {
          tempData.data.push(result.data.data);
          filter.page++;
          tempData.next = true;
          tempData.filter = filter;
        } else {
          if (
            tempData.data.length == 0 &&
            result.data.pagingInfo.totalPages == 1
          ) {
            tempData.data.push(result.data.data);
            tempData.next = false;
            tempData.filter = filter;
          } else {
            tempData.data.push(result.data.data);
            tempData.next = false;
            tempData.filter = filter;
          }
        }
        return tempData;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  /* A function that calls a service to get the color information. */
  async getColorInformation() {
    var result = "";
    await GeneralService.getColorInformation()
      .then((response) => {
        if (response.data) {
          result = response.data.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  },
  /* A function that calls a service to get fileds registry. */
  async getFieldsRegistry() {
    var result = "";
    var filter = {
      ListVariables: [
        "Address",
        "AffiliationType",
        "BirthDate",
        "Genre",
        "Locality",
        "MaritalStatus",
        "Neighborhood",
        "ParentDocument",
        "ParentName",
        "PatientCity",
        "PatientCountry",
        "PatientDepartment",
        "PatientType",
        "PersonalEmail",
        "PhoneNumber",
        "PlanCategory",
        "PlanType",
        "Religion",
        "SecondLastName",
        "SecondName",
        "SocialClass",
      ],
    };
    await GeneralService.getFieldsRegistry(filter)
      .then((response) => {
        if (response.data) {
          result = response.data.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  },
  /* This function is used to show a notification to the user. */
  openNotificationWithIcon(type, messageNotification, descNotification) {
    notification[type]({
      message: messageNotification,
      description: descNotification,
      placement: "center",
      style: {
        width: "400px",
      },
    });
  },
  /* This function is used to show a notification to the user. */
  openNotificationWithIconAndURL(
    type,
    messageNotification,
    descNotification,
    url,
    textUrl
  ) {
    notification[type]({
      message: messageNotification,
      description: (
        <div>
          {descNotification}
          <a target="_blank" href={url}>
            {textUrl}
          </a>
        </div>
      ),
      placement: "center",
      style: {
        width: "400px",
      },
    });
  },
  /**
   * It takes the JSON data from the API and returns an array of objects with the value and label.
   * @param json - the json object returned from the API call
   * @param key - The key in the data that you want to use as the value in the option.
   * @param value - the value of the option
   * @returns An array of objects with the value and label of each option.
   */
  processCommonData(data, key, value, optionInitial = false) {
    var options = [];
    if (optionInitial) {
      if (data.length > 0) {
        options.push(optionInitial);
      } else {
        options.push({
          value: undefined,
          label: "No se encontro información.",
        });
      }
    }
    if (data) {
      for (var i = 0; i < data.length; i++) {
        if (Array.isArray(value)) {
          var text = null;
          var image = null;
          var option = {};

          for (var j = 0; j < value.length; j++) {
            if (value[j] != "image") {
              if (text == null) {
                text = data[i][value[j]];
              } else {
                text += " - " + data[i][value[j]];
              }
            } else {
              image = data[i][value[j]];
            }
          }
          option.value = data[i][key];
          option.label = text;
          if (image != null) {
            option.image = image;
          }
          options.push(option);
        } else {
          options.push({
            value: data[i][key],
            label: data[i][value],
          });
        }
      }
    }
    return options;
  },
  /**
   * It takes the JSON data from the API and returns an array of objects with the value and label.
   * @param dataArray - the array object returned from the API call
   * @param key - The key in the data that you want to use as the value in the option.
   * @param value - the value of the option
   * @returns An array of objects with the value and label of each option.
   */
  processLargeCommonData(dataArray, key, value) {
    var options = [];
    if (dataArray.length > 0) {
      for (var i = 0; i < dataArray.length; i++) {
        for (var j = 0; j < dataArray[i].length; j++) {
          options.push({
            value: dataArray[i][j][key],
            label: dataArray[i][j][value],
          });
        }
      }
    }
    return options;
  },
  /**
   * Format a date string into a more readable format.
   * @param dateString - The date string to be formatted.
   * @param format - The format of the date.
   * @returns The date in the format specified.
   */
  formatDate(dateString, format) {
    var options = {};
    switch (format) {
      case "month":
        options = { month: "long" };
        break;
      case "day":
        options = { day: "numeric" };
        break;
      case "monthday":
        options = { month: "long", day: "numeric" };
        break;
      case "hour12":
        options = {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          hourCycle: "h11",
          timezone: "America/Bogota",
        };
        break;
      case "hour24":
        options = { hour: "numeric", minute: "numeric" };
        break;
      case "year":
        options = { year: "numeric" };
        break;
      case "monthofyear":
        options = { month: "long", year: "numeric" };
        break;
      case "completedate12":
        options = {
          month: "long",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        };
        break;
      case "completedate24":
        options = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        };
        break;
      case "simpleHour":
        return Dayjs(dateString).format("hh:mm A");
    }

    const date = new Date(dateString);
    // Then specify how you want your dates to be formatted
    var result = new Intl.DateTimeFormat("es-ES", options).format(date);
    return result;
  },
  /* A function that converts a date to a string in the format that the Web Service expects. */
  dateToWS(date) {
    var isoDateTime = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, -1);
    return isoDateTime;
  },

  /**
   * It gets a list of entities from an API, and then processes the data to return a list of options for
   * a select element.
   * @param filter - {planID: 1}
   * @returns An array of objects with the following structure:
   * [
   *   {
   *     value: 1,
   *     label: "Sede 1"
   *   }...
   * ]
   */
  async getEntitiesByPlanID(filter) {
    var entitiesData = { data: [] };
    filter.page = 0;
    filter.size = 50;

    let loadEntities = async (filter) => {
      return await EntitiesService.get({ params: filter })
        .then(loadData)
        .catch((error) => {
          return Promise.reject(error);
        });
    };

    let loadData = async (result) => {
      if (result.data.pagingInfo.hasNextPage) {
        entitiesData.data = entitiesData.data.concat(result.data.data);
        filter.page++;
        await loadEntities(filter);
      } else {
        if (
          entitiesData.length == 0 &&
          result.data.pagingInfo.totalPages == 1
        ) {
          entitiesData.data.push(result.data.data);
        } else {
          entitiesData.data = entitiesData.data.concat(result.data.data);
        }
        return Promise.resolve(entitiesData);
      }
    };

    var response = await loadEntities(filter);

    var options = this.processCommonData(
      response.data,
      "entityID",
      ["name", "address"],
      {
        value: -1,
        label: "Cualquier Sede",
      }
    );

    return options;
  },
  /**
   * It gets a list of doctors from an API, then it gets the specialty of each doctor from another API,
   * and then it returns the list of doctors with their specialty.
   * </code>
   * @param filter - Object to filter information
   * @param [loadSelect=false] - boolean
   */
  async getDoctorByEntityID(filter, loadSelect = false) {
    var doctorsData = { data: [] };
    filter.page = 0;
    filter.size = 50;
    filter.orderBy = "AvailabilityASC";
    filter.availableSchedule = true;
    filter.hiddenServiceGroups = false;

    let loadDoctors = async (filter) => {
      return await DoctorService.get(filter)
        .then(loadData)
        .catch((error) => {
          return Promise.reject(error);
        });
    };

    let loadSpecialtyDoctor = async (filter) => {
      return await DoctorSpecialtyService.get(filter)
        .then((result) => {
          var response = {};
          if (result.data) {
            response = result.data.data;
          }
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    };

    let loadData = async (result) => {
      if (result.data.pagingInfo.hasNextPage) {
        doctorsData.data = doctorsData.data.concat(result.data.data);
        filter.page++;
        await loadDoctors(filter);
      } else {
        if (doctorsData.length == 0 && result.data.pagingInfo.totalPages == 1) {
          doctorsData.data.push(result.data.data);
        } else {
          doctorsData.data = doctorsData.data.concat(result.data.data);
        }
        return Promise.resolve(doctorsData);
      }
    };

    var response = await loadDoctors(filter);
    if (loadSelect) {
      var options = this.processCommonData(
        response.data,
        "userID",
        ["name", "lastName", "image"],
        {
          value: -1,
          label: "Cualquier Doctor",
        }
      );
      var result = null;
      if (options.length > 0) {
        var userIDs = [];
        for (var i = 0; i < options.length; i++) {
          result = null;
          if (options[i].value && options[i].value != -1) {
            userIDs.push(options[i].value);
          }
        }
        result = await loadSpecialtyDoctor({
          userIDs: userIDs,
        });
        if (result.length > 0) {
          for (var a = 0; a < options.length; a++) {
            var specialy = result.find((f) => f.userID == options[a].value);
            if (specialy != undefined) {
              options[a].label += " - " + specialy.specialtyName;
            }
          }
        }
      }
    } else {
      if (response.data.length > 0) {
        result = null;
        result = await loadSpecialtyDoctor({
          userID: response.data[0].userID,
        });
        if (result.length > 0) {
          response.data[0].specialty = result[0].specialtyName;
        } else {
          response.data[0].specialty = "";
        }
      }
      options = response.data;
    }
    return options;
  },
  /**
   * It gets the details of a specific entity by ID.
   * @param entityID - The ID of the entity you want to get the details of.
   * @returns The response is an array of objects.
   */
  async getEntitiesDetailByID(entityID) {
    let loadEntities = async () => {
      return await EntitiesService.detail(entityID)
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    var response = await loadEntities();

    return response;
  },
  /**
   * It gets the details of a doctor by ID.
   * @param doctorID - The ID of the doctor you want to get.
   * @returns The response is an array of objects.
   */
  async getDoctorDetailByID(doctorID) {
    let loadSpecialtyDoctor = async (filter) => {
      return await DoctorSpecialtyService.get(filter)
        .then((result) => {
          var response = {};
          if (result.data) {
            response = result.data.data;
          }
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    };

    const loadDoctors = async () => {
      return await DoctorService.detail(doctorID)
        .then(async (result) => {
          if (result.data) {
            var specialty = await loadSpecialtyDoctor({
              userID: doctorID,
            });
            result.data.specialty = specialty[0].specialtyName;
            return result.data;
          } else {
            return {};
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    var response = await loadDoctors();

    return response;
  },
  /**
   * It returns the available schedules based on the filter.
   * @param filter - A filter object that contains the following properties:
   * @returns The response is an array of objects.
   */
  async getAvailableScheduleByFilter(filter) {
    const loadAvailableSchedule = async () => {
      return await ScheduleService.get({ params: filter })
        .then((result) => {
          if (result.data) {
            return result.data.data[0];
          } else {
            return {};
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    var response = await loadAvailableSchedule();

    return response;
  },
  /**
   * * If the response status is 400, then the function will return the error object.
   * * If the response status is not 400, then the function will return an error object with a generic
   * message
   * @param error - The error object.
   */
  handlePromiseError(error, origen = "", debug = false) {
    var objError = {};
    var message = "Se presento un error por favor comuniquese con la entidad";

    if (debug) {
      console.log(error.response);
      message = origen + " - " + message;
    }

    if (error.status && error.status >= 200) {
      error.response = error;
    }

    if (error.response) {
      if (error.response.status == 400) {
        objError = error.response.data;
        if (objError) {
          if (objError.modelState) {
            if (objError.modelState.start.length > 0) {
              if (debug) {
                message = origen + " - " + objError.modelState.start[0];
              } else {
                message = objError.modelState.start[0];
              }
              this.openNotificationWithIcon("error", "Validacion", message);
            }
          }
        }
      } else {
        this.openNotificationWithIcon("error", "Validacion", message);
      }
    }
  },
  /**
   * It's a function that calls another function that returns patient recover password process.
   * @param filter - Object to filter information
   * @returns The response is being returned.
   */
  async loadProcessPatientRecoverPassword(filter) {
    const processPatientRecoverPassword = async () => {
      return await PatientRecoverPasswordService.get({ params: filter })
        .then((result) => {
          if (result) {
            return result;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    var response = await processPatientRecoverPassword();
    return response;
  },
  /**
   * It returns a promise that resolves to the result of system variable.
   * </code>
   * @param variable - the variable name
   * @returns the response from the async function.
   */
  async loadSystemVariable(variable) {
    const processVariable = async () => {
      return await SystemVariableService.detail(variable)
        .then((result) => {
          if (result.data) {
            return result.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    var response = await processVariable();
    return response;
  },
  /**
   * function to load medical order information
   * @param filter - filter information
   * @returns The response is an array of objects.
   */
  async loadMedicalOrder(filter) {
    const processMedicalOrder = async () => {
      return await MedicalOrderService.get({ params: filter })
        .then((result) => {
          if (result.data) {
            return result.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    var response = await processMedicalOrder();
    return response;
  },
  /**
   * It load service plan information.
   * @param filter - filter data
   * @returns The response is an object with a data property that contains an array of objects.
   */
  async loadServicePlanInformation(filter) {
    const processServicePlan = async () => {
      return await ServicePlanService.get({ params: filter })
        .then((result) => {
          if (result.data) {
            return result.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    var response = await processServicePlan();
    return response;
  },
  /**
   * The function sleep() returns a promise that resolves after a given number of milliseconds.
   * @param ms - The number of milliseconds to wait before resolving the promise.
   * @returns A promise that resolves after the specified time.
   */
  async sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
  /* Calling a service to get the genre types. */
  async getModality() {
    var options = [
      {
        value: "Presencial",
        label: "Presencial",
      },
      {
        value: "Telemedicina",
        label: "Telemedicina",
      },
    ];

    return options;
  },
  /**
   * It gets a list of doctors from an API, then it gets the specialty of each doctor from another API,
   * and then it returns the list of doctors with their specialty by serviceID.
   * </code>
   * @param filter - Object to filter information
   * @param [loadSelect=false] - boolean
   */
  async getDoctorByServiceID(filter, loadSelect = false) {
    var doctorsData = { data: [] };
    filter.page = 0;
    filter.size = 50;
    filter.orderBy = "LastNameASC";
    filter.userType = "doctor";

    let loadDoctors = async (filter) => {
      return await DoctorService.get(filter)
        .then(loadData)
        .catch((error) => {
          return Promise.reject(error);
        });
    };

    let loadSpecialtyDoctor = async (filter) => {
      return await DoctorSpecialtyService.get(filter)
        .then((result) => {
          var response = {};
          if (result.data) {
            response = result.data.data;
          }
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    };

    let loadData = async (result) => {
      if (result.data.pagingInfo.hasNextPage) {
        doctorsData.data = doctorsData.data.concat(result.data.data);
        filter.page++;
        await loadDoctors(filter);
      } else {
        if (doctorsData.length == 0 && result.data.pagingInfo.totalPages == 1) {
          doctorsData.data.push(result.data.data);
        } else {
          doctorsData.data = doctorsData.data.concat(result.data.data);
        }
        return Promise.resolve(doctorsData);
      }
    };

    var response = await loadDoctors(filter);
    if (loadSelect) {
      var options = this.processCommonData(
        response.data,
        "userID",
        ["name", "lastName", "image"],
        {
          value: -1,
          label: "Cualquier Doctor",
        }
      );
      var result = null;
      if (options.length > 0) {
        //aa

        var userIDs = [];
        for (var i = 0; i < options.length; i++) {
          result = null;
          if (options[i].value && options[i].value != -1) {
            userIDs.push(options[i].value);
          }
        }
        result = await loadSpecialtyDoctor({
          userIDs: userIDs,
        });
        if (result.length > 0) {
          for (var a = 0; a < options.length; a++) {
            var specialy = result.find((f) => f.userID == options[a].value);
            if (specialy != undefined) {
              options[a].label += " - " + specialy.specialtyName;
            }
          }
        }
        // for (var i = 0; i < options.length; i++) {
        //   result = null;
        //   if (options[i].value && options[i].value != -1) {
        //     result = await loadSpecialtyDoctor({
        //       userID: options[i].value,
        //     });
        //     if (result) {
        //       options[i].label += " - " + result[0].specialtyName;
        //     }
        //   }
        // }
      }
    } else {
      if (response.data.length > 0) {
        result = null;
        result = await loadSpecialtyDoctor({
          userID: response.data[0].userID,
        });
        if (result) {
          response.data[0].specialty = result[0].specialtyName;
        }
      }
      options = response.data;
    }

    return options;
  },
  /**
   * It gets a list of custom texts from the server and returns them.
   * @returns An array of objects.
   */
  async getCustomTexts() {
    var texts = [];

    texts = await CustomTextService.get()
      .then((result) => {
        var data = [];
        if (result != null && result.data.data.length > 0) {
          data = result.data;
        }
        return data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return texts;
  },
  /**
   * It returns a promise that resolves to the data property of the response object returned by the
   * AppointmentService.detail() function.
   * @param filter - {
   * @returns The response.data object.
   */
  async getAppointmentById(filter) {
    return await AppointmentService.detail(filter)
      .then(async (response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  /**
   * It gets a list of custom images from the server and returns them.
   * @returns An array of objects.
   */
  async getCustomImages() {
    var images = [];

    images = await CustomImageService.get()
      .then((result) => {
        var data = [];
        if (result != null && result.data.data.length > 0) {
          data = result.data;
        }
        return data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return images;
  },

  /**
   * It returns a promise that resolves to a string.
   * @returns The result of the promise.
   */
  async getImagePath() {
    var result = "";
    await GeneralService.getImagesPath()
      .then((response) => {
        if (response.data) {
          result = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  },
  async valido(num) {
    return typeof num === "number" && num !== 0;
  },
  async MCD2(a, b) {
    if (this.valido(a) && this.valido(b)) {
      if (a < 0) a = -a;
      if (b < 0) b = -b;
      var r;
      while ((r = a % b)) {
        a = b;
        b = r;
      }
      return b;
    } else return false;
  },

  /**
   * The function `userValidations` is an asynchronous function that makes a request to a
   * userValidationsService API with a given filter, and returns a promise that resolves with the
   * result data or rejects with an error.
   * @param filter - The `filter` parameter is an object that contains the parameters to be passed to
   * the `userValidationsService.get()` method. These parameters are used to filter the data that will
   * be retrieved from the service.
   * @returns a promise that resolves to the data returned by the userValidationsService.get() method.
   */
  async userValidations(filter) {
    return await userValidationsService
      .get({
        params: filter,
      })
      .then(async (result) => {
        return Promise.resolve(result.data);
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },

  formatServiceName(
    appointmentID,
    serviceName,
    phase,
    extraData,
    appointmentServiceID = null
  ) {
    let names = [];

    if (serviceName) {
      names.push(serviceName);
    }

    if (phase && phase.length) {
      names = names.concat(
        phase
          .filter(
            (e) =>
              (e.appointmentID === appointmentID || e.appointmentID === 0) &&
              e.appointmentServiceID === appointmentServiceID
          )
          .map((e) => e.name)
      );
    }

    if (extraData && extraData.length) {
      names = names.concat(
        extraData
          .filter(
            (e) =>
              (e.appointmentID === appointmentID || e.appointmentID === 0) &&
              e.appointmentServiceID === appointmentServiceID
          )
          .map((e) => e.name)
      );
    }

    return names.join(" - ");
  },

  /**
   * The function `getWaitingList` is an asynchronous function that calls the `getWaitingList` method
   * from the `DoctorService` class with a given filter, and returns the result if it exists, otherwise
   * it returns an empty array.
   * @param filter - The `filter` parameter is an optional parameter that can be used to filter the
   * waiting list. It can be an object containing various properties that can be used to filter the
   * list, such as the doctor's name, the patient's name, or the appointment date.
   * @returns the result of the DoctorService.getWaitingList(filter) function if it is truthy (not null
   * or undefined), otherwise it returns an empty array.
   */
  async getWaitingList(filter) {
    try {
      const result = await DoctorService.getWaitingList(filter);
      if (result) {
        return result.data.map((e) => {
          return {
            value: e.userID,
            label: e.name + " " + e.lastName,
          };
        });
      }
    } catch (ex) {
      console.log(ex);
    }

    return [];
  },

  async getEmbebedDataInfo(filter) {
    return await embebedService
      .post(filter)
      .then(async (result) => {
        return Promise.resolve(result.data);
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },

  /**
   * The function `addOrUpdateTerms` asynchronously adds or updates terms in a model using the
   * `PatientAcceptanceService` and logs any errors.
   * @param model - The `model` parameter is an object that contains the data needed to add or update
   * terms in the patient acceptance service.
   */
  async addOrUpdateTerms(model) {
    try {
      await PatientAcceptanceService.addOrUpdate(model);
    } catch (error) {
      console.log("Error addOrUpdateTerms", error);
    }
  },
};

export default commonFunctions;
