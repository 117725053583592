<template>
  <div id="userInfo">
    <span v-if="user">Hola, {{ user.name }} {{ user.lastName }}</span>
  </div>
  <div id="divLogOut" @click="handleLogOut">
    <CloseCircleFilled /><span> Salir</span>
  </div>
</template>

<script>
import { useStore } from "vuex";
import CommonFunctions from "../common/functions";
import { useRouter } from "vue-router";
import { CloseCircleFilled } from "@ant-design/icons-vue";

export default {
  name: "HeaderConnected",
  components: {
    CloseCircleFilled,
  },
  setup() {
    const store = useStore();
    const user = store.state.auth.user;
    const route = useRouter();

    const handleLogOut = () => {
      CommonFunctions.openNotificationWithIcon(
        "success",
        "Hasta luego.",
        "Gracias por usar nuestro servicio."
      );
      store.dispatch("auth/logout");
      route.push({ name: "login" });
    };

    return {
      user,
      handleLogOut,
    };
  },
};
</script>

<style scoped>
#userInfo {
  font-size: 20px;
  padding-left: 12px;
}

#divLogOut {
  font-size: 20px;
  right: 0;
  padding: 0 3%;
  position: absolute;
  cursor: pointer;
  background-color: #fff;
}
</style>
