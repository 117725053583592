import externalActions from "./store/external-actions";
import externalState from "./store/external-state";
import externalMutations from "./store/external-mutations";
import externalGetters from "./store/external-getters";

/* This is the syntax for creating a Vuex module of external process. */
const external = {
  namespaced: true,
  state: externalState,
  actions: externalActions,
  mutations: externalMutations,
  getters: externalGetters,
};

export { external };
