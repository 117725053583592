import api from "./api";

/* This class is used to handle a service to process of schedule */
class ServiceService {
  /**
   * `detail(id)` returns the service with the given id
   * @param id - The ID of the service to retrieve.
   * @returns The service with the given id.
   */
  async detail(id) {
    return await api.get("/api/service/" + id);
  }

  /* This function is used to get all service from the server. */
  async get(filter) {
    return await api.get("/api/service/", filter);
  }
}
export default new ServiceService();
