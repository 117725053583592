/* Exporting patient care path state default object. */
export default {
  hasCarePath: false,
  CarePath: null,
  Insurer: null,
  Plan: null,
  isMultiService: false,
  serviceIDs: null,
  scheduleTime: null,
  phase: null,
  extraData: null,
  services: null,
  restrictionUserID: null,
  restrictionEntityID: null,
};
