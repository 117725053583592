import api from "./api";

class AppointmentPhaseService {
  get(filter) {
    return api.get("/api/appointmentphases", {
      params: filter,
    });
  }

  getRecommended(filter) {
    return api.get("/api/phases/recommended", {
      params: filter,
    });
  }
}

export default new AppointmentPhaseService();
