import InsurerPlanService from "@/services/insurer-plan-service";
import InsurerService from "@/services/insurer-service";
import ServiceService from "@/services/service-service";
import ServicePlanService from "@/services/service-plan-service";
import ServiceAlertService from "@/services/service-alert-service";
import AppointmentService from "@/services/appointment-service";
import DoctorService from "@/services/doctor-service";
import ServicePreparationsService from "../../../services/service-preparations-service";
import DoctorSpecialtyService from "../../../services/doctor-specialty-service";
import ServicePlanConditionService from "../../../services/service-plan-condition-service";
import ConditionService from "../../../services/condition-service";
import ExtraDataService from "../../../services/appointment-extradata-service";
import PhaseService from "../../../services/appointment-phase-service";

/* This is a JavaScript object that is being exported to the Vuex store. */
export default {
  /**
   * This function is used to load the plan information.
   * @param filter - The filter object that will be used to query the API.
   * @returns The promise is being returned.
   */
  async loadPlanInformation({ commit }, filter) {
    return await InsurerPlanService.detail(filter)
      .then(async (response) => {
        commit("PLAN_SUCCESS", response.data);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        commit("PLAN_FAILURE");
        return Promise.reject(error);
      });
  },
  /**
   * This function calls the API to get the insurer information.
   * @param filter - The filter object that will be used to query the API.
   * @returns The promise is being returned.
   */
  async loadInsurerInformation({ commit }, filter) {
    return await InsurerService.detail(filter)
      .then(async (response) => {
        commit("INSURER_SUCCESS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((error) => {
        commit("INSURER_FAILURE");
        return Promise.reject(error);
      });
  },
  /**
   * This function calls the API to get the service information.
   * @param filter - The filter object that will be used to query the API.
   * @returns The promise is being returned.
   */
  async loadServiceInformation({ commit }, filter) {
    return await ServiceService.detail(filter)
      .then(async (response) => {
        if (response && response.data) {
          commit("SERVICE_SUCCESS", response.data);
        }
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        commit("SERVICE_FAILURE");
        return Promise.reject(error);
      });
  },
  /**
   * This function calls the API to get the service plan information.
   * @param filter - The filter object that will be used to query the API.
   * @returns The promise is being returned.
   */
  async loadServicePlanInformation({ commit }, filter) {
    return await ServicePlanService.get({ params: filter })
      .then(async (response) => {
        if (response && response.data) {
          commit("SERVICEPLAN_SUCCESS", response.data.data[0]);
        }
        return Promise.resolve(response.data.data[0]);
      })
      .catch((error) => {
        commit("SERVICEPLAN_FAILURE");
        return Promise.reject(error);
      });
  },
  /**
   * This function is used to get the service alerts information.
   * @param filter - A filter object that is used to filter the results.
   * @returns The service alert information is being returned.
   */
  async loadServiceAlertsInformation({ commit }, filter) {
    return await ServiceAlertService.get({ params: filter })
      .then(async (response) => {
        if (response && response.data) {
          commit("SERVICEALERT_SUCCESS", response.data.data);
        }
        return Promise.resolve(response.data.data);
      })
      .catch((error) => {
        commit("SERVICEALERT_FAILURE");
        return Promise.reject(error);
      });
  },
  /**
   * This function is used to load the patient care path service.
   * @param data - The data that was returned from the API call.
   */
  async loadPatientCarePathService({ commit }, data) {
    if (data) {
      commit("CAREPATHSERVICE_SUCCESS", data);
    } else {
      commit("CAREPATHSERVICE_FAILURE");
    }
  },
  /**
   * This function is used to load the doctor appointment.
   * @param data - The data that was returned from the API call.
   */
  async loadDoctorInformation({ commit }, data) {
    if (data) {
      commit("DOCTOR_SUCCESS", data);
      return true;
    } else {
      commit("DOCTOR_FAILURE");
      return false;
    }
  },
  /**
   * This function is used to load the doctor by id.
   * @param data - The data that was returned from the API call.
   */
  async loadDoctorInformationByID({ commit }, doctorID) {
    return await DoctorService.detail(doctorID)
      .then(async (response) => {
        response.data.specialty = await DoctorSpecialtyService.get({
          userID: response.data.userID,
        })
          .then((result) => {
            var response = {};
            if (result.data) {
              response = result.data.data[0].specialtyName;
            }
            return response;
          })
          .catch((error) => {
            console.log(error);
          });
        commit("DOCTOR_SUCCESS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((error) => {
        commit("DOCTOR_FAILURE");
        return Promise.reject(error);
      });
  },
  /* This method clean patient process data. */
  clean({ commit }) {
    commit("PROCESS_CLEAN");
  },
  /**
   * This function is used to post the appointment data to the API.
   * @param model - The appointment data that will be sent to the server.
   * @returns The promise is returned.
   */
  async postAppointmentData({ commit }, model) {
    return await AppointmentService.post(model)
      .then((response) => {
        commit("APPOINTMENT_SUCCESS", response.data);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        commit("APPOINTMENT_FAILURE");
        return Promise.reject(error);
      });
  },
  /**
   * This function updates an appointment.
   * @param model - The appointment model that is being updated.
   * @returns The promise is being returned.
   */
  async putAppointmentData({ commit }, model) {
    return new Promise((resolve, reject) => {
      AppointmentService.put(model)
        .then(async (response) => {
          if (response.data) {
            if (model.userCarePathServiceID != null) {
              commit("APPOINTMENT_UPDATED", response.data);
            } else {
              commit("APPOINTMENTEXTERNAL_SUCCESS", response.data);
            }
          }
          return resolve(response);
        })
        .catch((error) => {
          if (model.userCarePathServiceID != null) {
            commit("APPOINTMENTUPDATE_FAILURE");
          } else {
            commit("APPOINTMENTEXTERNAL_FAILURE");
          }
          return reject(error);
        });
    });
  },
  /**
   * This function confirming the appointment by appotintemtnID
   * accordingly.
   * @param appointmentID - The `appointmentID` parameter is an integer ID.
   */
  async putConfirmAssistance({ commit }, appointmentID) {
    return new Promise((resolve, reject) => {
      AppointmentService.confirmAssistance(appointmentID)
        .then(async (response) => {
          return resolve(response);
        })
        .catch((error) => {
          commit("APPOINTMENTDETAIL_CLEAN");
          return reject(error);
        });
    });
  },
  /**
   * This function is used to get the service preparations information.
   * @param filter - A filter object that is used to filter the results.
   * @returns The service preparations information is being returned.
   */
  async loadServicePreparationsInformation({ commit }, filter) {
    return await ServicePreparationsService.get({ params: filter })
      .then(async (response) => {
        if (response && response.data) {
          commit("SERVICEPREPARATIONS_SUCCESS", response.data.data);
        }
        return Promise.resolve(response.data.data);
      })
      .catch((error) => {
        commit("SERVICEPREPARATIONS_FAILURE");
        return Promise.reject(error);
      });
  },
  /**
   * This function is used to get the service plan conditions information.
   * @param filter - A filter object that is used to filter the results.
   * @returns The service conditions information is being returned.
   */
  async loadServicePlanConditionsInformation({ commit }, filter) {
    return await ServicePlanConditionService.get({ params: filter })
      .then(async (response) => {
        if (response && response.data) {
          commit("SERVICEPLANCONDITIONS_SUCCESS", response.data.data);
        }
        return Promise.resolve(response.data.data);
      })
      .catch((error) => {
        commit("SERVICEPLANCONDITIONS_FAILURE");
        return Promise.reject(error);
      });
  },
  /**
   * This function is used to get the service conditions information.
   * @param filter - A filter object that is used to filter the results.
   * @returns The service conditions information is being returned.
   */
  async loadServiceConditionsInformation({ commit }, filter) {
    return await ConditionService.get({ params: filter })
      .then(async (response) => {
        if (response && response.data) {
          commit("SERVICECONDITIONS_SUCCESS", response.data.data);
        }
        return Promise.resolve(response.data.data);
      })
      .catch((error) => {
        commit("SERVICECONDITIONS_FAILURE");
        return Promise.reject(error);
      });
  },
  /* This method cancel selection patient process data. */
  cancel({ commit }) {
    commit("PROCESS_CANCEL");
  },
  /**
   * It's an async function appointment external to patient care path  if the response is
   * successful is save in store, and rejected if the response is not successful
   * @param filter - {
   * @returns The response.data object.
   */
  async loadAppointmentAdditionalInformation({ commit }, filter) {
    return await AppointmentService.detail(filter)
      .then(async (response) => {
        commit("APPOINTMENTEXTERNAL_SUCCESS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((error) => {
        commit("APPOINTMENTEXTERNAL_FAILURE");
        return Promise.reject(error);
      });
  },
  /**
   * Load information of medical order in store
   * @param data - {
   * @returns The return value is a boolean.
   */
  async loadMedicalOrderInformation({ commit }, data) {
    if (data) {
      commit("MEDICALORDER_SUCCESS", data);
      return true;
    } else {
      commit("MEDICALORDER_FAILURE");
      return false;
    }
  },
  /**
   * "This function is used to clean the medical order information."
   * </code>
   * I'm not sure if this is the best way to do this, but it's working for me.
   */
  async cleanMedicalOrderInformation({ commit }) {
    commit("MEDICALORDER_FAILURE");
  },

  /**
   * This is an asynchronous function that loads phase based on a filter and commits the result to the
   * store.
   * @param filter - The `filter` parameter is likely an object that contains filters or criteria to be
   * used when fetching data from the `PhaseService`. It could include things like date ranges, status
   * filters, or other parameters that help narrow down the results returned by the service.
   */
  async loadPhase({ commit }, filter) {
    const result = await PhaseService.get(filter);
    if (result.status === 200) {
      if (result.data?.data?.length > 0) {
        commit("PHASES_SUCCESS", result.data?.data);
      } else {
        commit("PHASES_FAILURE");
      }
    } else {
      commit("PHASES_FAILURE");
    }
  },

  /**
   * This is an asynchronous function that loads extra data based on a filter and updates the state
   * with the result.
   * @param filter - The `filter` parameter is an object that is used to filter the data that is
   * retrieved from the `ExtraDataService`. It is likely used to specify certain criteria for the data
   * that is needed, such as a date range or specific keywords.
   */
  async loadExtraData({ commit }, filter) {
    const result = await ExtraDataService.get(filter);
    if (result.status === 200) {
      commit("EXTRA_DATA_SUCCESS", result.data?.data);
    } else {
      commit("EXTRA_DATA_FAILURE");
    }
  },

  /**
   * This function loads recommended phase based on a given filter and updates the state accordingly.
   * @param filter - The `filter` parameter is an object that contains filters to be applied when
   * fetching recommended phase. It is passed as an argument to the `getRecommended` method of the
   * `PhaseService` object. The specific properties of the `filter` object depend on the implementation
   * of the `getRecommended` method
   */
  async loadRecommendedPhase({ commit }, filter) {
    try {
      const result = await PhaseService.getRecommended(filter);
      if (result.status === 200) {
        commit("PHASES_SUCCESS", result.data);
      } else {
        commit("PHASES_FAILURE");
      }
    } catch (error) {
      commit("PHASES_FAILURE");
    }
  },

  /**
   * This function loads recommended extra data based on a given filter and updates the state
   * accordingly.
   * @param filter - The `filter` parameter is an object that contains filters to be applied to the
   * data being fetched from the `ExtraDataService`. It is used to retrieve recommended extra data
   * based on certain criteria.
   */
  async loadRecommendedExtraData({ commit }, filter) {
    try {
      const result = await ExtraDataService.getRecommended(filter);
      if (result.status === 200) {
        commit("EXTRA_DATA_SUCCESS", result.data);
      } else {
        commit("EXTRA_DATA_FAILURE");
      }
    } catch (error) {
      commit("EXTRA_DATA_FAILURE");
    }
  },
};
