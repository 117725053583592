import api from "./api";

/* A service that provides a way to interact with the Country Type API. */
class CountryService {
  /**
   * Get all countries
   * @param filter - A filter to apply to the query.
   * @returns The response is a JSON object.
   */
  get(filter) {
    return api.get("/api/country/", filter);
  }
}
export default new CountryService();
