export default {
  /**
   * Get all the care path services that are scheduled
   * @param state - The state of the care path services.
   * @returns The care path services that are scheduled.
   */
  getCarePathServicesScheduled(state) {
    return state.carePathServices.filter(
      (carePathService) => carePathService.state == 2
    );
  },
  /**
   * Get all the care path services that are active
   * @param state - The state of the care path services.
   * @returns The care path services that are active.
   */
  getCarePathServicesActive(state) {
    return state.carePathServices.filter(
      (carePathService) => carePathService.state == 0
    );
  },
  /**
   * Get all the care path services that are not available
   * @param state - The state of the care path services.
   * @returns The Care Path Services that are not available.
   */
  getCarePathServicesNotAvailable(state) {
    return state.carePathServices.filter(
      (carePathService) => carePathService.state == 1
    );
  },
  /**
   * Get all the care path services that have been deleted
   * @param state - The state of the care path services.
   * @returns The care path services that have been deleted.
   */
  getCarePathServicesDeleted(state) {
    return state.carePathServices.filter(
      (carePathService) => carePathService.state == 3
    );
  },
  /**
   * Get all the care path services that are expired
   * @param state - The state of the care path service.
   * @returns The `getCarePathServicesExpired` function returns a filtered array of care path services
   * that are expired.
   */
  getCarePathServicesExpired(state) {
    var now = Date.now();
    return state.carePathServices.filter(
      (carePathService) =>
        carePathService.state == 0 &&
        carePathService.startDate >= now &&
        carePathService.HasValidityTime &&
        carePathService.DateValidityTime < now
    );
  },
  /**
   * Get all the care path services that are not deleted and have the same userCarePathServiceID as the
   * one passed in
   * @param state - The state of the store.
   * @param id - The ID of the user care path service.
   * @returns An array of care path services.
   */
  getPatientCarePathServicesByID: (state) => (id) => {
    var result = state.carePathServicesList.filter(
      (carePathService) => carePathService.userCarePathServiceID == id
    );
    return result[0];
  },
  /**
   * Get all the care path services that are not deleted and have the same appointmentID as the
   * one passed in
   * @param state - The state of the store.
   * @param id - The ID of the appointment ID.
   * @returns An array of care path services.
   */
  getPatientCarePathServicesByAppointmentID: (state) => (id) => {
    var result = state.carePathServicesList.filter(
      (carePathService) => carePathService.appointmentID == id
    );
    return result[0];
  },
};
