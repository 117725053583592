import api from "./api";

/* This class is used to handle a service plan conditions to process of schedule */
class ServicePlanConditionService {
  /* This function is used to get all service plan conditions from the server. */
  async get(filter) {
    return await api.get("/api/servicePlanCondition/", filter);
  }
}
export default new ServicePlanConditionService();
