const Profile = () => import("../view/profile.vue");
const ProfilePasswordRecovery = () =>
  import("../components/profile-password-recovery.vue");

/* Exporting the object routes of profile module. */
export default [
  {
    name: "profile",
    path: "/profile",
    meta: {
      Title: "Perfil Principal",
      layout: "layout-connected",
      showMenu: true,
    },
    component: Profile,
  },
  {
    name: "passwordrecovery",
    path: "/passwordrecovery",
    meta: {
      Title: "Principal",
      layout: "layout-connected",
      showMenu: false,
    },
    component: ProfilePasswordRecovery,
  },
];
