import api from "./api";

/* A service that provides a way to interact with the Department countries API. */
class DepartmentService {
  /**
   * Get all departments
   * @param filter - A filter to apply to the query.
   * @returns The response is a JSON object.
   */
  get(filter) {
    return api.get("/api/department/", filter);
  }
}
export default new DepartmentService();
