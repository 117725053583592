/* Exporting process appointment service mutations. */
export default {
  /* process successful load respose data initial to process */
  PROCESS_SUCCESS(state, processData) {
    state.entityID = processData.entityID;
    state.doctorID = processData.doctorID;
    state.dateAppointment = processData.dateAppointment;
    state.hourAppointment = processData.hourAppointment;
  },
  /* Setting the state to null if response failure. */
  PROCESS_FAILURE(state) {
    state.entityID = null;
    state.doctorID = null;
    state.dateAppointment = null;
    state.hourAppointment = null;
  },
  /* Setting all the state to null. */
  PROCESS_CLEAN(state) {
    state.insurerData = null;
    state.planData = null;
    state.serviceData = null;
    state.servicePlanData = null;
    state.serviceAlertsData = null;
    state.servicePlanConditions = null;
    state.servicePreparations = null;
    state.serviceConditions = null;
    state.entityID = null;
    state.doctorID = null;
    state.dateAppointment = null;
    state.hourAppointment = null;
    state.patientCarePathService = null;
    state.appointmentAdditional = null;
    state.medicalOrderData = null;
  },
  /* Setting the state.insurerData to the insurerData. */
  INSURER_SUCCESS(state, insurerData) {
    state.insurerData = insurerData;
  },
  /* Setting the state.insurerData to an empty array. */
  INSURER_FAILURE(state) {
    state.insurerData = [];
  },
  /* Setting the state.planData to the planData. */
  PLAN_SUCCESS(state, planData) {
    state.planData = planData;
  },
  /* Setting the state.planData to an empty array. */
  PLAN_FAILURE(state) {
    state.planData = [];
  },
  /* Setting the state.serviceData to the serviceData. */
  SERVICE_SUCCESS(state, serviceData) {
    state.serviceData = serviceData;
  },
  /* Setting the state.serviceData to an empty array. */
  SERVICE_FAILURE(state) {
    state.serviceData = [];
  },
  /* Setting the state.servicePlanData to the servicePlanData. */
  SERVICEPLAN_SUCCESS(state, servicePlanData) {
    state.servicePlanData = servicePlanData;
  },
  /* Setting the state.servicePlanData to an empty array. */
  SERVICEPLAN_FAILURE(state) {
    state.servicePlanData = [];
  },
  /* Setting the state.serviceAlertsData to the serviceAlertService. */
  SERVICEALERT_SUCCESS(state, serviceAlertService) {
    state.serviceAlertsData = serviceAlertService;
  },
  /* Setting the state.serviceAlertsData to an empty array. */
  SERVICEALERT_FAILURE(state) {
    state.serviceAlertsData = [];
  },
  /* Setting the state.patientCarePathService to the patientCarePathService. */
  CAREPATHSERVICE_SUCCESS(state, patientCarePathService) {
    state.patientCarePathService = patientCarePathService;
  },
  /* Setting the state.patientCarePathService to an empty array. */
  CAREPATHSERVICE_FAILURE(state) {
    state.patientCarePathService = [];
  },
  /* Setting the state.patientCarePathService.appointment to the appointmentData. */
  APPOINTMENT_SUCCESS(state, appointmentData) {
    state.patientCarePathService.appointment = appointmentData;
    state.patientCarePathService.appointmentID = appointmentData.appointmentID;
  },
  /* Setting the state.patientCarePathService.appointment to null and
  state.patientCarePathService.appointmentID to null. */
  APPOINTMENT_FAILURE(state) {
    state.patientCarePathService.appointment = null;
    state.patientCarePathService.appointmentID = null;
  },
  /* Setting the state.patientCarePathService.appointment to the appointmentData. */
  APPOINTMENT_UPDATED(state, appointmentData) {
    state.patientCarePathService.appointment = appointmentData;
  },
  /* Setting the state.patientCarePathService.appointment to null and
   state.patientCarePathService.appointmentID to null. */
  APPOINTMENTUPDATE_FAILURE(state) {
    state.patientCarePathService.appointment = null;
    state.patientCarePathService.appointmentID = null;
  },
  /* Setting the state.doctorData to the doctorData. */
  DOCTOR_SUCCESS(state, doctorData) {
    state.doctorData = doctorData;
  },
  /* Setting the state.doctorData to an empty array. */
  DOCTOR_FAILURE(state) {
    state.doctorData = [];
  },
  /* Setting the state.servicePreparations to the preparationsData. */
  SERVICEPREPARATIONS_SUCCESS(state, preparationsData) {
    state.servicePreparations = preparationsData;
  },
  /* Setting the state.servicePreparations to an empty array. */
  SERVICEPREPARATIONS_FAILURE(state) {
    state.servicePreparations = [];
  },
  /* Setting the state.serviceConditions to the conditionsData. */
  SERVICECONDITIONS_SUCCESS(state, conditionsData) {
    state.serviceConditions = conditionsData;
  },
  /* Setting the state.serviceConditions to an empty array. */
  SERVICECONDITIONS_FAILURE(state) {
    state.serviceConditions = [];
  },
  /* Setting the state.servicePlanConditions to the servicePlanConditionsData. */
  SERVICEPLANCONDITIONS_SUCCESS(state, servicePlanConditionsData) {
    state.servicePlanConditions = servicePlanConditionsData;
  },
  /* Setting the state.servicePlanConditions to an empty array. */
  SERVICEPLANCONDITIONS_FAILURE(state) {
    state.servicePlanConditions = [];
  },
  /* Setting the state.appointmentAdditional to the appointmentData. */
  APPOINTMENTEXTERNAL_SUCCESS(state, appointmentData) {
    state.appointmentAdditional = appointmentData;
  },
  /* Setting the state.appointmentAdditional to null. */
  APPOINTMENTEXTERNAL_FAILURE(state) {
    state.appointmentAdditional = null;
  },
  /* Setting the state to null. */
  PROCESS_CANCEL(state) {
    state.entityID = null;
    state.doctorID = null;
    state.dateAppointment = null;
    state.hourAppointment = null;
  },
  /* Setting the state.medicalOrderData to the medicalOrderData. */
  MEDICALORDER_SUCCESS(state, medicalOrderData) {
    medicalOrderData.hasMedicalOrder = true;
    state.medicalOrderData = medicalOrderData;
  },
  /* Setting the state.medicalOrderData to null. */
  MEDICALORDER_FAILURE(state) {
    state.medicalOrderData = null;
  },
  /* Setting the state.phase to the phase. */
  PHASES_SUCCESS(state, data) {
    state.phase = data;
  },
  /* Setting the state.phase to null. */
  PHASES_FAILURE(state) {
    state.phase = null;
  },
  /* Setting the state.extraData to the extra data. */
  EXTRA_DATA_SUCCESS(state, data) {
    state.extraData = data;
  },
  /* Setting the state.extraData to null. */
  EXTRA_DATA_FAILURE(state) {
    state.extraData = null;
  },
};
