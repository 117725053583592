import api from "./api";

/* A service that provides a way to interact with the patient Type API. */
class PatientTypeService {
  /**
   * Get all patient types
   * @param filter - A filter to apply to the query.
   * @returns The response is a JSON object.
   */
  get(filter) {
    return api.get("/api/patienttype/", filter);
  }
}
export default new PatientTypeService();
