export default {
  /**
   * Get true or false if must update time information
   * @param state - The state of the store.
   * @param datetime - datetime to evaluate
   * @returns An boolean result.
   */
  mustUpdateTimeInformation: (state) => (date) => {
    var data = state.avaliableTimesSchedule.filter((timesSchedule) =>
      timesSchedule.dateTime.includes(date)
    );

    if (data.length > 0) {
      return true;
    } else {
      return false;
    }
  },
  /**
   * Get true or false if must update closest information
   * @param state - The state of the store.
   * @param datetime - datetime to evaluate
   * @returns An boolean result.
   */
  mustUpdateClosestInformation: (state) => (date) => {
    var data = state.avaliableClosestSchedule.filter((closestSchedule) =>
      closestSchedule.dateTime.includes(date)
    );

    if (data.length > 0) {
      return true;
    } else {
      return false;
    }
  },
};
