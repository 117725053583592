import api from "./api";

/* A service that provides a way to interact with the custom text API. */
class CustomTextService {
  /**
   * `detail(id)` returns custom text with the given id
   * @param id - The ID of the patient care path to retrieve.
   * @returns The patient care path with the given id.
   */
  detail(id) {
    return api.get("/api/customfronttext/" + id);
  }
  /**
   * Get all custom front texts
   * @param filter - A filter to apply to the query.
   * @returns The response is a JSON object.
   */
  get(filter) {
    return api.get("/api/customfronttext/", filter);
  }
  /**
   * It creates a new appointment.
   * @param model - The model that will be sent to the server.
   * @returns The `post` method returns a `Promise` object.
   */
  post(model) {
    return api.post("/api/customfronttext/", model);
  }
  /**
   * It updates an appointment.
   * @param model - The model that will be sent to the server.
   * @returns The `put` method returns a `Promise` object.
   */
  async put(model) {
    return api.put("/api/customfronttext/", model);
  }
}
export default new CustomTextService();
