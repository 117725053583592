/* Exporting schedule service mutations. */
export default {
  /* If response of schedule Api response successful load information in state */
  SCHEDULE_SUCCESS(state, scheduleData) {
    state.avaliableDatesSchedule = scheduleData;
    state.updateSchedule = false;
    state.updateDateTime = null;
  },
  /* If response of schedule Api response failure clean state */
  SCHEDULE_FAILURE(state) {
    state.avaliableDatesSchedule = [];
    state.updateSchedule = false;
    state.updateDateTime = null;
  },
  /* If response of schedule Api response successful load information in state */
  SCHEDULECLOSEST_SUCCESS(state, scheduleData) {
    state.avaliableClosestSchedule = scheduleData;
    state.updateSchedule = false;
    state.updateDateTime = null;
  },
  /* If response of schedule Api response failure clean state */
  SCHEDULECLOSEST_FAILURE(state) {
    state.avaliableClosestSchedule = [];
    state.updateSchedule = false;
    state.updateDateTime = null;
  },
  /* If response of schedule Api response successful load information in state */
  SCHEDULETIME_SUCCESS(state, scheduleData) {
    state.avaliableTimesSchedule = scheduleData;
    state.updateSchedule = false;
    state.updateDateTime = null;
  },
  /* If response of schedule Api response failure clean state */
  SCHEDULETIME_FAILURE(state) {
    state.avaliableTimesSchedule = [];
    state.updateSchedule = false;
    state.updateDateTime = null;
  },
  /* if signalr response is success and is need update hours this state variable is updated */
  SCHEDULETIME_UPDATE(state, value) {
    state.updateTimeSchedule = value;
  },
  /* if signalr response is success  and is need update dates this state variable is updated */
  SCHEDULEDATETIME_UPDATE(state, value) {
    state.updateDateTime = value;
  },
  /* if success response load filter available. */
  SCHEDULEFILTER_SUCCESS(state, filter) {
    state.filterAvailableSchedule = filter;
  },
  /* clean filter available. */
  SCHEDULEFILTER_CLEAN(state) {
    state.filterAvailableSchedule = null;
  },
  /**
   * "SCHEDULE_CLEAN" is a function that sets the state of the "avaliableDatesSchedule" array to an
   * empty array, sets the state of the "updateSchedule" boolean to false, and sets the state of the
   * "updateDateTime" to null.
   * @param state - the state object
   */
  SCHEDULE_CLEAN(state) {
    state.avaliableDatesSchedule = [];
    state.updateSchedule = false;
    state.updateDateTime = null;
  },

  /**
   * The function clears the available times for scheduling and resets the update flags.
   * @param state - The "state" parameter is an object that represents the current state of the
   * application or program. It contains various properties and values that store information and
   * control the behavior of the program. In this case, the "state" object likely contains properties
   * related to scheduling and time management.
   */
  SCHEDULE_TIME_CLEAN(state) {
    state.avaliableTimesSchedule = [];
    state.updateSchedule = false;
    state.updateDateTime = null;
  },
};
