const eventBus = {
  /* Adding an event listener to the document. */
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  /* Dispatching an event to the document. */
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  /* Removing the event listener. */
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
};
export default eventBus;
