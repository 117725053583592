import api from "./api";

/* This class is used to handle a service to system variable */
class SystemVariableService {
  /**
   * `detail(id)` returns the system variable with the given id
   * @param id - The ID of the service to retrieve.
   * @returns The service with the given id.
   */
  async detail(id) {
    return await api.get("/api/systemVariable/" + id);
  }

  /* This function is used to get all system variable from the server by filter. */
  async get(filter) {
    return await api.get("/api/systemVariable/", filter);
  }
}
export default new SystemVariableService();
