import api from "./api";

/* A service that provides a way to interact with the Embedded API. */
class EmbebedService {
  /**
   * `post(embeddedReq)` returns if the patient has a valid user
   * @param id - The patient ID.
   * @returns A boolean.
   */
  async post(filter) {
    return await api.post("/api/Embedded/", filter);
  }
}
export default new EmbebedService();
