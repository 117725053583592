import api from "./api";

/* A service that provides a way to interact with the city API. */
class CityService {
  /**
   * `detail(id)` returns the city with the given id
   * @param id - The city ID.
   * @returns The city details.
   */
  detail(id) {
    return api.get("/api/city/" + id);
  }

  /**
   * Get all info of cities from the database
   * @param filter - The filter to apply to the query.
   * @returns The information data.
   */
  get(filter) {
    return api.get("/api/city/", {
      params: filter,
    });
  }
}
export default new CityService();
