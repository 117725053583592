/* Exporting appointment service mutations. */
export default {
  /**
   * It takes a state and a patientAppointment object as parameters, and then it sets the state's
   * AppointmentScheduled, AppointmentCanceled, or AppointmentExpired property to the data property of
   * the patientAppointment object, depending on the value of the type property of the
   * patientAppointment object.
   * @param state - The state object
   * @param patientAppointment - {
   */
  APPOINTMENT_SUCCESS(state, patientAppointment) {
    switch (patientAppointment.type) {
      case "scheduled":
        state.AppointmentScheduled = patientAppointment.data;
        break;
      case "canceled":
        state.AppointmentCanceled = patientAppointment.data;
        break;
      case "expired":
        state.AppointmentExpired = patientAppointment.data;
        break;
    }
  },
  /**
   * When the user clicks on the appointment, the appointment detail is displayed.
   * @param state - The state object
   * @param patientAppointment - {
   */
  APPOINTMENTDETAIL_SUCCESS(state, patientAppointment) {
    state.AppointmentDetail = patientAppointment;
  },
  /**
   * If the Appointment API response failure, then set it to null.
   * @param state - The current state of the store.
   */
  APPOINTMENTDETAIL_FAILURE(state) {
    state.AppointmentDetail = null;
  },
  /**
   * When the user clicks the 'Cancel' button, the appointment detail is set to null.
   * @param state - This is the state object that we are going to be modifying.
   */
  APPOINTMENTDETAIL_CLEAN(state) {
    state.AppointmentDetail = null;
  },
  /* Setting the state's AppointmentScheduled, AppointmentCanceled, AppointmentExpired,
  appointmentDetail, and ServiceWaitingList properties to an empty array. */
  APPOINTMENT_FAILURE(state) {
    state.AppointmentScheduled = [];
    state.AppointmentCanceled = [];
    state.AppointmentExpired = [];
    state.appointmentDetail = null;
    state.ServiceWaitingList = [];
  },
  /**
   * When the user log out, clear the appointment data from the store.
   * @param state - The state object
   */
  APPOINTMENT_CLEAN(state) {
    state.AppointmentScheduled = [];
    state.AppointmentCanceled = [];
    state.AppointmentExpired = [];
    state.appointmentDetail = null;
    state.ServiceWaitingList = [];
  },
  /**
   * function that takes two parameters, state and cancelationResons,
   * and sets the state.CancelReasons to cancelationResons.
   * @param state - The current state of the store.
   * @param cancelationResons - is an array of objects
   */
  CANCELATIONREASONS_SUCCESS(state, cancelationResons) {
    state.CancelReasons = cancelationResons;
  },
  /**
   * if cancellation reasons API response failure, sets the state.CancelReasons to an empty array
   * @param state - The current state of the store.
   */
  CANCELATIONREASONS_FAILURE(state) {
    state.CancelReasons = [];
  },
  /**
   * The function takes in a state and a waitingListData object, and pushes the serviceId property of
   * the waitingListData object into the ServiceWaitingList array of the state object.
   * @param state - The current state of the store
   * @param waitingListData - {
   */
  WAITINGLIST_SUCCESS(state, waitingListData) {
    state.ServiceWaitingList.push(waitingListData.serviceId);
  },
};
