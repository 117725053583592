import api from "./api";

/* This class is used to get medical order patient by filter. */
class MedicalOrderService {
  /**
   * get medical order patient by filter
   * @returns The response from the server.
   */
  async get(filter) {
    return await api.get("/api/medicalorderspatient/", filter);
  }
}
export default new MedicalOrderService();
