import profileActions from "./store/profile-actions";
import profileState from "./store/profile-state";
import profileMutations from "./store/profile-mutations";
import profileGetters from "./store/profile-getters";

/* This is the syntax for creating a Vuex module of patient profile module. */
const profile = {
  namespaced: true,
  state: profileState,
  actions: profileActions,
  mutations: profileMutations,
  getters: profileGetters,
};

export { profile };
