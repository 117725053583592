/* Exporting login mutations. */
export default {
  /**
   * When the LOADING_PROCESS function is called, the state.loading property is set to true.
   * @param state - This is the state object.
   */
  LOADING_PROCESS(state) {
    state.loading = true;
  },
  /**
   * When the loading is finished, set the loading state to false.
   * @param state - The state object
   */
  LOADING_FINISH(state) {
    state.loading = false;
  },
};
