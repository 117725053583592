import api from "./api";
class GeneralService {
  /**
   * get color information of api system variable
   * @returns An array of objects.
   */
  getColorInformation() {
    var filter = {
      like: "Color",
    };
    return api.get("/api/systemVariable", {
      params: filter,
    });
  }
  /**
   * It returns the value of the system variable "ImagesPath" from the database.
   * @returns The response from the server.
   */
  getImagesPath() {
    return api.get("/api/systemVariable/ImagesPath");
  }
  /**
   * It returns the value of the system variable fields to registry from the database.
   * @returns An array of objects.
   */
  getFieldsRegistry(filter) {
    return api.get("/api/systemVariable", { params: filter });
  }
}
export default new GeneralService();
