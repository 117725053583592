import CustomTextService from "../../../services/custom-text-service";
import CustomImageService from "../../../services/custom-image-service";

export default {
  async loadCustomTextInformation({ commit }, data) {
    if (data) {
      commit("CUSTOMTEXT_SUCCESS", data);
      return true;
    } else {
      commit("CUSTOMTEXT_FAILURE");
      return false;
    }
  },
  /**
   * If data is true, commit the data to the store, otherwise commit a failure.
   * @param data - {
   * @returns The return value is a boolean.
   */
  async loadCustomImageInformation({ commit }, data) {
    if (data) {
      commit("CUSTOMIMAGE_SUCCESS", data);
      return true;
    } else {
      commit("CUSTOMIMAGE_FAILURE");
      return false;
    }
  },
  /* It calls the logout method of the AuthService */
  async updateCustomText({ commit }, model) {
    return CustomTextService.put(model).then(
      async (result) => {
        var texts = [];
        texts = await CustomTextService.get()
          .then((results) => {
            var data = [];
            if (results != null && results.data.data.length > 0) {
              data = results.data;
            }
            return data;
          })
          .catch(function (error) {
            console.log(error);
          });
        /* This is a mutation that is being called in the JavaScript file. */
        commit("CUSTOMTEXT_SUCCESS", texts);
        return Promise.resolve(result);
      },
      (error) => {
        commit("CUSTOMTEXT_FAILURE");
        return Promise.reject(error);
      }
    );
  },
  /**
   * It takes a model, sends it to the server, and returns the response.
   * @param model - {
   * @returns The response.data is being returned.
   */
  async uploadImage({ commit }, model) {
    return await CustomImageService.post(model)
      .then((response) => {
        commit("TEMPORALIMAGE_SUCCESS", response.data);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  async updateCustomImage({ commit }, model) {
    return CustomImageService.put(model).then(
      async (result) => {
        var images = [];
        images = await CustomImageService.get()
          .then((results) => {
            var data = [];
            if (results != null && results.data.data.length > 0) {
              data = results.data;
            }
            return data;
          })
          .catch(function (error) {
            console.log(error);
          });
        /* This is a mutation that is being called in the JavaScript file. */
        commit("CUSTOMIMAGE_SUCCESS", images);
        return Promise.resolve(result);
      },
      (error) => {
        commit("CUSTOMIMAGE_FAILURE");
        return Promise.reject(error);
      }
    );
  },
  clean({ commit }) {
    commit("TEMPORALIMAGE_CLEAN");
  },
};
