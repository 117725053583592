import AppointmentService from "@/services/appointment-service";
import CancelationReasonService from "@/services/cancelation-reason-service";
import ServicePreparationsService from "@/services/service-preparations-service";
import ServiceAlertService from "@/services/service-alert-service";
import ConditionService from "@/services/condition-service";
import ServicePlanConditionService from "@/services/service-plan-condition-service";
import PatientWaitingListService from "@/services/patient-waiting-list-service";

/* This is a JavaScript object that is being exported to the Vuex store. */
export default {
  /**
   * This method get patient appointments.
   * The `async` keyword is used to indicate that the function is asynchronous.
   * @param model - The model to filter that is being passed to the API.
   * @returns The data is being returned as a promise.
   */
  async getAppointments({ commit }, filter) {
    var appointmentData = { type: "", data: [] };
    var paginator = filter.paginator;
    appointmentData.type = filter.type;
    appointmentData.pagingInfo = {};

    delete filter.type;
    delete filter.paginator;

    let loadAppointments = async (filter) => {
      return await AppointmentService.get({ params: filter })
        .then(loadData)
        .catch((error) => {
          commit("APPOINTMENT_FAILURE");
          return Promise.reject(error);
        });
    };

    let loadData = async (result) => {
      appointmentData.pagingInfo = result.data.pagingInfo;
      if (!paginator) {
        if (result.data.pagingInfo.hasNextPage) {
          appointmentData.data = appointmentData.data.concat(result.data.data);
          filter.page++;
          await loadAppointments(filter);
        } else {
          if (
            appointmentData.length == 0 &&
            result.data.pagingInfo.totalPages == 1
          ) {
            appointmentData.data = result.data.data;
          } else {
            appointmentData.data = appointmentData.data.concat(
              result.data.data
            );
          }
          commit("APPOINTMENT_SUCCESS", appointmentData);
          return Promise.resolve(appointmentData);
        }
      } else {
        appointmentData.data = result.data.data;
        commit("APPOINTMENT_SUCCESS", appointmentData);
        return Promise.resolve(appointmentData);
      }
    };

    var response = await loadAppointments(filter);

    return response;
  },
  /**
   * This function retrieves the appointment detail by appointment ID.
   * @param appointmentID - The ID of the appointment to retrieve.
   * @returns The response from the API call.
   */
  async getAppointmentDetailByID({ commit }, model) {
    let loadAppointmentByID = async (appointmentID) => {
      return await AppointmentService.detail(appointmentID)
        .then(loadData)
        .catch((error) => {
          commit("APPOINTMENTDETAIL_FAILURE");
          return Promise.reject(error);
        });
    };

    const buildAlerts = () => {
      let alerts = [];

      if (model.phase && model.phase.length) {
        alerts = alerts.concat(
          model.phase
            .filter((e) => e.alerts !== null)
            .map((e) => {
              return {
                phaseID: e.phaseID,
                serviceID: e.serviceID,
                appointmentServiceID: e.appointmentServiceID,
                name: e.name,
                alerts: e.alerts.replace(/[\r\n]/gm, "<br />"),
              };
            })
        );
      }

      return alerts;
    };

    const buildPreparations = () => {
      let preparations = [];

      if (model.extraData && model.extraData.length) {
        preparations = preparations.concat(
          model.extraData
            .filter((e) => e.preparations !== null)
            .map((e) => {
              return {
                extraDataID: e.extraDataID,
                serviceID: e.serviceID,
                appointmentServiceID: e.appointmentServiceID,
                name: e.name,
                preparations: e.preparations.replace(/[\r\n]/gm, "<br />"),
              };
            })
        );
      }

      return preparations;
    };

    const buildPhasePreparations = () => {
      let preparations = [];

      if (model.phase && model.phase.length) {
        preparations = preparations.concat(
          model.phase
            .filter((e) => e.preparations !== null)
            .map((e) => {
              return {
                phaseID: e.phaseID,
                serviceID: e.serviceID,
                appointmentServiceID: e.appointmentServiceID,
                name: e.name,
                preparations: e.preparations.replace(/[\r\n]/gm, "<br />"),
              };
            })
        );
      }

      return preparations;
    };

    let loadData = async (result) => {
      if (result.data) {
        var preparations = await ServicePreparationsService.get({
          params: {
            serviceID: result.data.serviceID,
            type: "1",
            hidden: true,
          },
        })
          .then((resultPreparations) => {
            if (resultPreparations.data.data.length > 0) {
              return resultPreparations.data.data;
            } else {
              return [];
            }
          })
          .catch((error) => {
            commit("APPOINTMENTDETAIL_FAILURE");
            return Promise.reject(error);
          });

        var alerts = await ServiceAlertService.get({
          params: {
            serviceID: result.data.serviceID,
            hidden: true,
          },
        })
          .then((resultAlerts) => {
            if (resultAlerts.data.data.length > 0) {
              return resultAlerts.data.data;
            } else {
              return [];
            }
          })
          .catch((error) => {
            commit("APPOINTMENTDETAIL_FAILURE");
            return Promise.reject(error);
          });

        var conditions = await ConditionService.get({
          params: {
            serviceID: result.data.serviceID,
            planID: result.data.planID,
          },
        })
          .then((resultConditions) => {
            if (resultConditions.data.data.length > 0) {
              return resultConditions.data.data;
            } else {
              return [];
            }
          })
          .catch((error) => {
            commit("APPOINTMENTDETAIL_FAILURE");
            return Promise.reject(error);
          });

        var planConditions = await ServicePlanConditionService.get({
          params: {
            serviceID: result.data.serviceID,
            planID: result.data.planID,
            status: false,
          },
        })
          .then((resultPlanConditions) => {
            if (resultPlanConditions.data.data.length > 0) {
              return resultPlanConditions.data.data;
            } else {
              return [];
            }
          })
          .catch((error) => {
            commit("APPOINTMENTDETAIL_FAILURE");
            return Promise.reject(error);
          });

        const secondPreparations = await AppointmentService.preparations(
          model.appointmentID
        );

        const phaseAlerts = buildAlerts();
        const extraPreparations = buildPreparations();
        const phasePreparations = buildPhasePreparations();

        result.data.preparations = preparations;
        result.data.alerts = alerts;
        result.data.conditions = conditions;
        result.data.planConditions = planConditions;
        result.data.extraAlerts = phaseAlerts;
        result.data.extraPreparations = extraPreparations;
        result.data.phasePreparations = phasePreparations;
        result.data.secondPreparations = secondPreparations.data;
        commit("APPOINTMENTDETAIL_SUCCESS", result.data);
        return Promise.resolve(result.data);
      } else {
        return Promise.reject(result);
      }
    };

    var response = await loadAppointmentByID(model.appointmentID);

    return response;
  },
  /* This method clean patient appointments data. */
  clean({ commit }) {
    commit("APPOINTMENT_CLEAN");
  },
  /* This method clean patient appointments data. */
  cleanDetail({ commit }) {
    commit("APPOINTMENTDETAIL_CLEAN");
  },
  /**
   * This function calls the cancellation reasons get method and passes in the filter.
   * @param filter - The filter to use to get the cancelation reasons list.
   * @returns A cancelation reasons list is being returned.
   */
  async loadCancelationReasons({ commit }, filter) {
    return await CancelationReasonService.get({ params: filter })
      .then(async (response) => {
        if (response && response.data) {
          commit("CANCELATIONREASONS_SUCCESS", response.data);
        }
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        commit("CANCELATIONREASONS_FAILURE");
        return Promise.reject(error);
      });
  },
  /**
   * This function updates an appointment.
   * @param model - The appointment model that is being updated.
   * @returns The promise is being returned.
   */
  async deleteAppointment({ commit }, model) {
    return new Promise((resolve, reject) => {
      AppointmentService.delete(model)
        .then(async (response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          commit("APPOINTMENTDETAIL_CLEAN");
          return reject(error);
        });
    });
  },
  /**
   * This function is used to post the waiting list data to the API.
   * @param model - The appointment data that will be sent to the server.
   * @returns The promise is returned.
   */
  async postAwaitingListData({ commit }, model) {
    return await PatientWaitingListService.post(model)
      .then((response) => {
        commit("WAITINGLIST_SUCCESS", response.data);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
};
