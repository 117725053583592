import api from "./api";

/* A service that provides a way to interact with the Patient preregister API. */
class PatientPreregisterService {
  /**
   * `detail(id)` returns the patient preregister with the given id
   * @param id - The patient ID.
   * @returns The patient's details.
   */
  detail(id) {
    return api.get("/api/patientpreregister/" + id);
  }

  /**
   * Get all patient preregister info from the database
   * @param filter - The filter to apply to the query.
   * @returns The information data.
   */
  get(filter) {
    return api.get("/api/patientpreregister/", filter);
  }
  /**
   * `post(model)` is a function that takes a model as an argument and returns a promise
   * @param model - The model that will be sent to the server.
   * @returns The response of proccess create.
   */
  post(model) {
    return api.post("/api/patientpreregister/", model);
  }
  /**
   * `put(model)` is a function that takes a model as an argument and returns a promise
   * @param model - The model that you want to put.
   * @returns The response of proccess update.
   */
  async put(model) {
    return api.put("/api/patientpreregister/", model);
  }
  /**
   * `delete(model)`
   *
   * The function takes a model as an argument and returns a promise
   * @param model - The model to delete.
   * @returns The response of proccess delete.
   */
  delete(model) {
    return api.delete("/api/patientpreregister/", model);
  }
}
export default new PatientPreregisterService();
