<template>
  <a-menu
    v-model:selectedKeys="current"
    mode="inline"
    theme="light"
    v-if="showMenu"
  >
    <a-menu-item
      v-for="menuItem in menuList"
      class="custom-menu-item"
      :key="menuItem.key"
      @click="handleMenuClick(menuItem.key)"
    >
      <component v-if="menuItem.icon" :is="menuItem.icon"></component>
      <span>{{ menuItem.label }}</span>
    </a-menu-item>
  </a-menu>
</template>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
//import CommonFunctions from "../common/functions";
import {
  MailOutlined,
  ProjectOutlined,
  ProfileOutlined,
} from "@ant-design/icons-vue";

export default {
  name: "LeftSideMenuConnected",
  components: {
    MailOutlined,
    ProjectOutlined,
    ProfileOutlined,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = store.state.auth.user;
    let current = ref(["carepath"]);
    const showMenu = computed(() => {
      if (router.currentRoute.value.meta.showMenu == false) {
        return false;
      } else {
        return true;
      }
    });

    const menuList = [
      {
        key: "carepath",
        label: "Tus citas",
        icon: ProjectOutlined,
        route: "/carepath",
      },
      {
        key: "profile",
        label: "Perfil",
        icon: ProfileOutlined,
        route: "/profile",
      },
    ];

    const addAdmin = () => {
      var btoken = JSON.parse(localStorage.getItem("auth-data"));
      if (btoken && btoken.scopes) {
        var data = btoken.scopes.split(",");
        for (var i = 0; i < data.length; i++) {
          if (data[i] == "Administrador") {
            menuList.push({
              key: "admin",
              label: "Landing",
              icon: ProfileOutlined,
              route: "/admin",
            });
          }
        }
      }
    };
    addAdmin();
    const handleMenuClick = (key) => {
      if (key != current.value[0]) {
        router.push({ name: key });
      }
    };

    return {
      user,
      handleMenuClick,
      menuList,
      current,
      showMenu,
      addAdmin,
    };
  },
};
</script>
<style scoped>
.ant-menu {
  font-size: 20px !important;
}
.ant-menu-item .anticon {
  font-size: 20px !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon {
  font-size: 20px !important;
}
</style>
