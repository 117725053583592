import { createStore } from "vuex";
import { auth, patientpreregister } from "../modules/login/login-module";
import {
  patientCarePath,
  patientCarePathService,
  patientAppointment,
} from "../modules/carepath/carepath-module";
import {
  patientProcess,
  patientSchedule,
} from "../modules/schedule/schedule-module";
import { profile } from "../modules/profile/profile-module";
import { external } from "../modules/external/external-module";
import { page } from "./page-module";
import { admin } from "../modules/admin/admin-module";

/* It creates a store object with the modules, getters, state, mutations, and actions. */
const store = createStore({
  modules: {
    auth,
    patientpreregister,
    page,
    profile,
    patientCarePath,
    patientCarePathService,
    patientAppointment,
    patientProcess,
    patientSchedule,
    external,
    admin,
  },
  getters: {},
  state: {},
  mutations: {},
  actions: {},
});

export default store;
