const CarePath = () => import("../view/carepath.vue");

/* Exporting the object routes of care path module. */
export default {
  name: "carepath",
  path: "/carepath",
  meta: {
    Title: "Ruta de cuidado",
    layout: "layout-connected",
    showMenu: true,
  },
  props: true,
  component: CarePath,
};
