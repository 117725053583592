import api from "./api";

/* A service that provides a way to interact with the entity API. */
class EntityService {
  /**
   * `detail(id)` returns the entity with the given id
   * @param id - The insurer ID.
   * @returns The insurer's details.
   */
  detail(id) {
    return api.get("/api/entity/" + id);
  }

  /**
   * Get all info of entity from the database
   * @param filter - The filter to apply to the query.
   * @returns The information data.
   */
  get(filter) {
    return api.get("/api/entity/", filter);
  }
}
export default new EntityService();
