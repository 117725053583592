/* Exporting care path service mutations. */
export default {
  CUSTOMTEXT_SUCCESS(state, customTextInfo) {
    state.CustomTextData = customTextInfo;
  },
  CUSTOMTEXT_FAILURE(state) {
    state.CustomTextData = null;
  },
  CUSTOMTEXT_CLEAN(state) {
    state.CustomTextData = null;
  },
  CUSTOMIMAGE_SUCCESS(state, customImageInfo) {
    state.CustomImageData = customImageInfo;
  },
  CUSTOMIMAGE_FAILURE(state) {
    state.CustomImageData = null;
  },
  CUSTOMIMAGE_CLEAN(state) {
    state.CustomImageData = null;
  },
  TEMPORALIMAGE_SUCCESS(state, customImageInfo) {
    state.TemporalImage = customImageInfo;
  },
  TEMPORALIMAGE_CLEAN(state) {
    state.TemporalImage = null;
  },
};
