/* Getting the data from local storage and parsing it into a JSON object. */
const preRegisterData = JSON.parse(localStorage.getItem("preregister-data"));

/* Exporting user preregister state default object. */
var initialPreregister =
  preRegisterData == null
    ? {
        status: { isPreregistered: false, isRegistered: false },
        user: null,
      }
    : {
        status: { isPreregistered: true, isRegistered: false },
        user: preRegisterData,
      };

export default {
  ...initialPreregister,
};
