import LinkNotificationService from "../../../services/link-notification-service";
import LinkRedirectService from "../../../services/link-redirect-service";

/* This is a JavaScript object that is being exported to the Vuex store. */
export default {
  /**
   * This method get link redirect information.
   * The `async` keyword is used to indicate that the function is asynchronous.
   * @param model - The model that is being passed to the API.
   * @returns The data is being returned as a promise.
   */
  async getLinkRedirectInformation({ commit }, model) {
    return await LinkRedirectService.get(model).then(
      (result) => {
        /* This is a mutation that is being called in the JavaScript file. */
        if (result) {
          commit("EXTERNAL_SUCCESS", result);
        }
        return Promise.resolve(result);
      },
      (error) => {
        commit("EXTERNAL_FAILURE");
        return Promise.reject(error);
      }
    );
  },
  /**
   * This method get link notification information.
   * The `async` keyword is used to indicate that the function is asynchronous.
   * @param model - The model that is being passed to the API.
   * @returns The data is being returned as a promise.
   */
  async getLinkNotificationInformation({ commit }, model) {
    return await LinkNotificationService.get(model).then(
      (result) => {
        if (result.data) {
          var data = {
            info: {
              value: 0,
              action: result.data.getParameter,
              user: result.data.patientID,
              token: result.data.token,
            },
            name: "",
          };
          if (result.data.setParameter != null) {
            data.info.value = parseInt(result.data.setParameter);
          }
          /* This is a switch statement that is being used to determine the value of the `data.name`
          variable. */
          switch (result.data.getParameter) {
            //process to cancel appointment
            case "cancelAppointment":
              data.name = "Cancellation";
              break;
            //process to reschedule appointment
            case "AppointmentID":
              data.name = "Reschedule";
              break;
            //process waiting list
            case "waitingListId":
              data.name = "WaitingList";
              break;
            //process recover password
            case "IdUser":
              data.name = "RecoverPassword";
              break;
            default:
              if (
                result.data.getParameter == null &&
                result.data.setParameter == null &&
                result.data.linkRedirectID == 2
              ) {
                data.name = "Profile";
              } else {
                data.name = "Login";
              }
              break;
          }
          commit("EXTERNAL_SUCCESS", data);
        }
        return Promise.resolve(data);
      },
      (error) => {
        commit("EXTERNAL_FAILURE");
        return Promise.reject(error);
      }
    );
  },
  /* This method clean external information data. */
  clean({ commit }) {
    commit("EXTERNAL_CLEAN");
  },
};
