import api from "./api";

/* A service that provides a way to interact with the Appointment API. */
class AppointmentService {
  /**
   * `detail` is a function that takes an id and returns the appointment with that id
   * @param id - The id of the appointment to retrieve.
   * @returns The appointment with the given id.
   */
  detail(id) {
    return api.get("/api/appointmentcarepath/" + id);
  }

  /**
   * It returns a list of appointments with care path.
   * @param filter - A filter object that specifies the query parameters.
   * @returns The data is being returned as JSON.
   */
  get(filter) {
    return api.get("/api/appointmentcarepath/", filter);
  }

  /**
   * It creates a new appointment with care path.
   * @param model - The model that will be sent to the server.
   * @returns The `post` method returns a `Promise` object.
   */
  post(model) {
    return api.post("/api/appointmentcarepath/", model);
  }

  /**
   * It updates an appointment with care path.
   * @param model - The model that will be sent to the server.
   * @returns The `put` method returns a `Promise` object.
   */
  async put(model) {
    return api.put("/api/appointmentcarepath/", model);
  }

  /**
   * `delete(model)` It delete an appointment.
   * @param model - The model to delete.
   * @returns The `delete` method returns a `Promise` object.
   */
  delete(model) {
    return api.delete("/api/appointmentcarepath/", {
      data: model,
    });
  }

  /**
   * The function "preparations" makes an API call to retrieve the preparations for a specific
   * appointment.
   * @param appointmentID - The appointmentID parameter is a unique identifier for a specific
   * appointment. It is used to retrieve the preparations for that appointment from the API.
   * @returns The function `preparations(appointmentID)` is returning the result of an API call to
   * retrieve the preparations for a specific appointment.
   */
  preparations(appointmentID) {
    return api.get(`/api/appointment/${appointmentID}/services/preparations`);
  }

  /**
   * The function `getMultiService` retrieves the multi-service information for a given appointment ID.
   * @param appointmentID - The appointmentID parameter is the unique identifier for a specific
   * appointment. It is used to retrieve the multiservice information associated with that appointment.
   * @returns the result of the API call to retrieve the multiservice information for the given
   * appointment ID.
   */
  getMultiService(appointmentID) {
    return api.get(`api/appointment/${appointmentID}/multiservice`);
  }
  /**
   * The function `confirmAssistance` handle the multi-service information for a given appointment ID.
   * @param appointmentID - The appointmentID parameter is the unique identifier for a specific
   * appointment. It is used to retrieve the multiservice information associated with that appointment.
   * @returns the result of the API call to retrieve the multiservice information for the given
   * appointment ID.
   */
  confirmAssistance(appointmentID) {
    return api.put(`api/appointment/confirmassistance/${appointmentID}`);
  }
}
export default new AppointmentService();
