import api from "./api";

/* A service that provides a way to interact with the Patient API. */
class PatientService {
  /**
   * `detail(id)` returns the patient with the given id
   * @param id - The patient ID.
   * @returns The patient's details.
   */
  async detail(id) {
    return await api.get("/api/patient/" + id);
  }

  /**
   * Get all get info from the database
   * @param filter - The filter to apply to the query.
   * @returns The information data.
   */
  async get(filter) {
    return api.get("/api/patient/", filter);
  }
  /**
   * `post(model)` is a function that takes a model as an argument and returns a promise
   * @param model - The model that will be sent to the server.
   * @returns The response of proccess create.
   */
  async post(model) {
    return api.post("/api/patient/", model);
  }
  /**
   * `put(model)` is a function that takes a model as an argument and returns a promise
   * @param model - The model that you want to put.
   * @returns The response of proccess update.
   */
  async put(model) {
    return api.put("/api/patient/", model);
  }
  /**
   * `delete(model)`
   *
   * The function takes a model as an argument and returns a promise
   * @param model - The model to delete.
   * @returns The response of proccess delete.
   */
  async delete(model) {
    return api.delete("/api/patient/", model);
  }

  /* The `async sendConfirmMessage(id)` function is a method of the `PatientService` class. It sends a
  confirmation message to a patient with the given `id`. It makes a POST request to the
  `/api/patient/{id}/confirmmessage` endpoint using the `api` module. The function returns a
  promise that resolves with the response of the request. */
  async sendConfirmMessage(id) {
    return api.post(`/api/patient/${id}/confirmmessage`);
  }
}
export default new PatientService();
