/* Exporting login mutations. */
export default {
  /**
   * if user is preregistered load information on the store
   * @param state - The state object
   * @param patientAppointment - {
   */
  VERIFY_PREREGISTER_SUCCESS(state, user) {
    state.status.isPreregistered = true;
    state.status.isRegistered = false;
    state.user = user;
  },
  /**
   * If the user is not preregistered, then they are not registered and the user is null.
   * @param state - The current state of the store.
   */
  VERIFY_PREREGISTER_FAILURE(state) {
    state.status.isPreregistered = false;
    state.status.isRegistered = false;
    state.user = null;
  },
  /**
   * register user process success then load information in store
   * @param state - The current state of the store.
   */
  REGISTER_SUCCESS(state, user) {
    state.status.isPreregistered = false;
    state.status.isRegistered = true;
    state.user = user;
  },
  /**
   * register user process failure, then the user is null.
   * @param state - The current state of the store.
   */
  REGISTER_FAILURE(state) {
    state.status.isPreregistered = false;
    state.status.isRegistered = false;
    state.user = null;
  },
  /**
   * clean process, and sets the user to null
   * @param state - The state object
   */
  PREREGISTER_CLEAN(state) {
    state.status.isPreregistered = false;
    state.status.isRegistered = false;
    state.user = null;
  },
};
