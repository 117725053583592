import api from "./api";

/* A service that provides a way to interact with the pattient acceptance API. */
class PatientAcceptanceService {
  /**
   * The addOrUpdate function sends a PUT request to the "/api/patientacceptance/addorupdate" endpoint
   * with the provided model data.
   * @param model - The `model` parameter is an object that contains the data to be added or updated.
   * It is passed as an argument to the `addOrUpdate` function.
   * @returns the result of the API call to the "/api/patientacceptance/addorupdate" endpoint with the
   * provided model as the payload.
   */
  addOrUpdate(model) {
    return api.put("/api/patientacceptance/addorupdate", model);
  }
}
export default new PatientAcceptanceService();
