import api from "./api";

/* A service that provides a way to interact with the Insurer plan API. */
class InsurerPlanService {
  /**
   * `detail(id)` returns the insurer plan with the given id
   * @param id - The insurer plan ID.
   * @returns The insurer plan's details.
   */
  async detail(id) {
    return await api.get("/api/plan/" + id);
  }

  /**
   * Get all info of insurer insurer plans from the database
   * @param filter - The filter to apply to the query.
   * @returns The information data.
   */
  async get(filter) {
    return await api.get("/api/plan/", {
      params: filter,
    });
  }
}
export default new InsurerPlanService();
