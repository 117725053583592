import adminActions from "./store/admin-actions";
import adminState from "./store/admin-state";
import adminMutations from "./store/admin-mutations";
import adminGetters from "./store/admin-getters";

/* This is the syntax for creating a Vuex module. */
const admin = {
  namespaced: true,
  state: adminState,
  actions: adminActions,
  mutations: adminMutations,
  getters: adminGetters,
};

export { admin };
