/* Exporting care path service mutations. */
export default {
  /**
   * If Patient profile info API response valid data set the state.
   * @param state - The state object
   * @param carePath - The care path object that is returned from the API.
   */
  PROFILE_SUCCESS(state, profileInfo) {
    state.profileData = profileInfo;
  },
  /**
   * If Patient profile info API response invalid data or error set the state.profileData to null.
   */
  PROFILE_FAILURE(state) {
    state.profileData = null;
  },
  /* Cleaning the state.profileData. */
  PROFILE_CLEAN(state) {
    state.profileData = null;
  },
};
