/* Exporting care path service mutations. */
export default {
  /**
   * Set the information of care path service in success process.
   * @param state - The state object that is being updated.
   * @param carePathServices - The care path service returned by patient care path service API.
   */
  CAREPATH_SERVICES_SUCCESS(state, carePathServices) {
    state.carePathServices.push(carePathServices.data);
    state.carePathServicesList = state.carePathServicesList.concat(
      carePathServices.data
    );
  },
  /**
   * If the API call fails, set the state of care path services an empty array.
   * @param state - The state object
   */
  CAREPATH_SERVICES_FAILURE(state) {
    state.carePathServices = [];
    state.carePathServicesList = [];
  },

  /* Cleaning the state of care path services. */
  CAREPATH_SERVICES_CLEAN(state) {
    state.carePathServices = [];
    state.carePathServicesList = [];
  },
};
