import api from "./api";

/* A service that provides a way to interact with the custom text API. */
class CustomTextService {
  /**
   * `detail(id)` returns custom text with the given id
   * @param id - The ID of the patient care path to retrieve.
   * @returns The patient care path with the given id.
   */
  detail(id) {
    return api.get("/api/customFrontImage/" + id);
  }
  /**
   * Get all custom front texts
   * @param filter - A filter to apply to the query.
   * @returns The response is a JSON object.
   */
  get(filter) {
    return api.get("/api/customFrontImage/", filter);
  }

  /**
   * I'm trying to upload a file to a server using axios.
   * </code>
   * @param model - {fileName: "", tagID: "", temporal: ""}
   * @returns The response from the server.
   */
  post(model) {
    var fileData = new FormData();
    fileData.append("files", model.file);

    return api.post(
      "/api/customFrontImage?fileName=" +
        model.fileName +
        "&tagID=" +
        model.tagID +
        "&temporal=" +
        model.temporal,
      fileData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  /**
   * I'm trying to upload a file to a server using axios.
   * </code>
   * @param model - {
   * @param file - is the file that I'm uploading
   * @returns The response from the server.
   */
  async put(model) {
    var fileData = new FormData();
    fileData.append("files", model.file);

    return api.put(
      "/api/customFrontImage?fileName=" +
        model.fileName +
        "&tagID=" +
        model.tagID +
        "&temporal=" +
        model.temporal,
      fileData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
}
export default new CustomTextService();
