import axiosInstance from "./api";
import TokenService from "./token-service";

const setup = (store) => {
  /* Token Interceptor for request. */
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  /* Intercepting the response from the back-end. If the response is 401, it will try to refresh the
  access token. */
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "/token" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            let dataRefresh = new URLSearchParams();

            dataRefresh.append("grant_type", "refresh_token");
            dataRefresh.append(
              "refresh_token",
              TokenService.getLocalRefreshToken()
            );
            const rs = await axiosInstance
              .post("/token", dataRefresh, {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  "No-Auth": "True",
                },
              })
              .then((result) => {
                return result;
              })
              .catch((error) => {
                store.dispatch("auth/logout");
                return Promise.reject(error);
              });

            if (rs.status == 200) {
              const { access_token } = rs.data;
              TokenService.updateLocalAccessToken(access_token);
              store.dispatch("auth/refreshToken", access_token);
            }
            if (rs.status == 400) {
              store.dispatch("auth/logout");
            }

            return axiosInstance(originalConfig);
          } catch (_error) {
            store.dispatch("auth/logout");
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};
export default setup;
