import api from "./api";

/* A service that provides a way to interact with the link redirect API. */
class LinkRedirectService {
  /**
   * Get info to link redirect by guid from the database
   * @param filter - The filter to apply to the query.
   * @returns The information data.
   */
  get(filter) {
    return api.get("/api/linkredirect?linkRedirectId=" + filter);
  }
}
export default new LinkRedirectService();
