/* Creating a class called `TokenService` that has a constructor that takes no arguments. */
class TokenService {
  /**
   * Get the refresh token from local storage
   * @returns The refresh token.
   */
  getLocalRefreshToken() {
    const authData = JSON.parse(localStorage.getItem("auth-data"));
    return authData?.refresh_token;
  }
  /**
   * Get the access token from the local storage
   * @returns The access token for the user.
   */
  getLocalAccessToken() {
    const authData = JSON.parse(localStorage.getItem("auth-data"));
    return authData?.access_token;
  }
  /**
   * Update the local storage with the new access token
   * @param token - The access token that you want to update.
   */
  updateLocalAccessToken(token) {
    let authData = JSON.parse(localStorage.getItem("auth-data"));
    authData.access_token = token;
    localStorage.setItem("auth-data", JSON.stringify(authData));
  }
  /**
   * Get the token from local storage
   * @returns The token is being returned.
   */
  getToken() {
    return JSON.parse(localStorage.getItem("auth-data"));
  }
  /**
   * Set the token in local storage
   * @param authData - The authentication data returned from the server.
   */
  setToken(authData) {
    localStorage.setItem("auth-data", JSON.stringify(authData));
  }
  /**
   * Remove the token from local storage
   */
  removeToken() {
    localStorage.removeItem("auth-data");
  }
}
export default new TokenService();
