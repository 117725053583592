import PatientPreregisteredService from "@/services/patient-preregistered-service";

/* This is a JavaScript object that is being exported to the Vuex store. */
export default {
  /**
   * This method get information if user is preregistered.
   * The `async` keyword is used to indicate that the function is asynchronous.
   * @param model - The model that is being passed to the API.
   * @returns The data is being returned as a promise.
   */
  async isPreregisterUser({ commit }, model) {
    return await PatientPreregisteredService.get({ params: model }).then(
      (result) => {
        if (result.data.data && result.data.data.length > 0) {
          var info = result.data.data[0];
          info.documentNumber = model.documentID;
          info.documentType = model.documentTypeID;
          localStorage.setItem("preregister-data", JSON.stringify(info));
          commit("VERIFY_PREREGISTER_SUCCESS", info);
        } else {
          commit("VERIFY_PREREGISTER_FAILURE");
        }
        return Promise.resolve(result.data.data);
      },
      (error) => {
        commit("VERIFY_PREREGISTER_FAILURE");
        return Promise.reject(error);
      }
    );
  },
  /**
   * This method get information if user is registered.
   * The `async` keyword is used to indicate that the function is asynchronous.
   * @param model - The model that is being passed to the API.
   * @returns The data is being returned as a promise.
   */
  async isRegisterUser({ commit }, model) {
    return await PatientPreregisteredService.get({ params: model }).then(
      (result) => {
        if (result.data.data && result.data.data.length > 0) {
          var info = result.data.data[0];
          info.documentNumber = model.documentID;
          info.documentType = model.documentTypeID;
          localStorage.setItem("preregister-data", JSON.stringify(info));
          commit("REGISTER_SUCCESS", info);
        } else {
          commit("REGISTER_FAILURE");
        }
        return Promise.resolve(result.data.data);
      },
      (error) => {
        commit("REGISTER_FAILURE");
        return Promise.reject(error);
      }
    );
  },
  /**
   * It takes a model, sends it to the server, and then commits the result to the store
   * @param model - {
   * @returns The result of the promise.
   */
  updatePreregisterUser({ commit }, model) {
    return PatientPreregisteredService.put(model)
      .then((result) => {
        if (result.data) {
          commit("REGISTER_SUCCESS", result.data);
        } else {
          commit("REGISTER_FAILURE");
        }
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  /* A method that is being called from the component to clean the store. */
  clean({ commit }) {
    commit("PREREGISTER_CLEAN");
  },
};
