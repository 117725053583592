import api from "./api";

/* A service that provides a way to interact with the Patient recover password API. */
class PatientRecoverPasswordService {
  /**
   * Get all get info of patient recover password from the database
   * @param filter - The filter to apply to the query.
   * @returns The information data.
   */
  get(filter) {
    return api.get("/api/patientRecoverPassword", filter);
  }
  /**
   * `put(model)` is a function that takes a model as an argument and returns a promise
   * @param model - The model that you want to put.
   * @returns The response of proccess update.
   */
  async put(model) {
    return api.put("/api/patientRecoverPassword/", model);
  }
}
export default new PatientRecoverPasswordService();
