import { createRouter, createWebHistory } from "vue-router";

import LoginRoutes from "../modules/login/index";
import ProfileRoutes from "../modules/profile/index";
import ScheduleRoutes from "../modules/schedule/index";
import CarePathRoutes from "../modules/carepath/index";
import ExternalRoutes from "../modules/external/index";
import Adminroutes from "../modules/admin/index";

const list = [
  ...LoginRoutes,
  ...ProfileRoutes,
  ...ScheduleRoutes,
  ...CarePathRoutes,
  ...ExternalRoutes,
  ...Adminroutes,
];
let routes = [];

list.forEach((items) => {
  if (Array.isArray(items)) {
    items.forEach((item) => {
      routes.push(item);
    });
  } else {
    routes.push(items);
  }
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
