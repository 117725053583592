import axios from "axios";

/* Creating an instance of axios with the baseURL and headers. */
const instance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  validateStatus: function (status) {
    return status <= 400;
  },
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
export default instance;
