<template>
  <a-layout>
    <a-layout-sider
      v-model:collapsed="collapsed"
      collapsible
      theme="light"
      breakpoint="md"
      :trigger="null"
      collapsed-width="0"
    >
      <!-- <img
        class="logo"
        width="80px"
        height="auto"
        src="../assets/img/medicarte-logo.svg"
      /> -->
      <div class="logo-toolBar logo" width="80px" height="auto">
        <i
          v-if="isAdmin"
          id="logo-toolBar"
          @click="openCustomImageModal('logo-toolBar')"
          ><font-awesome-icon :icon="['far', 'edit']"
        /></i>
      </div>
      <TheLeftSideSection />
    </a-layout-sider>
    <a-layout class="wrapper-layout" style="width: 100%">
      <a-layout-header
        style="background: #fff; padding: 0; display: inline-flex"
      >
        <menu-unfold-outlined
          v-if="collapsed"
          class="trigger logo-trigger"
          @click="() => (collapsed = !collapsed)"
        />
        <menu-fold-outlined
          v-else
          class="trigger logo-trigger"
          @click="() => (collapsed = !collapsed)"
        />
        <TheHeaderConnected />
      </a-layout-header>
      <a-layout-content>
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
  <div><CustomImageModal ref="modalCustomImage" /></div>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import TheLeftSideSection from "./the-left-side-section.vue";
import TheHeaderConnected from "./the-header.vue";
import CustomImageModal from "../modules/admin/components/custom-image-modal.vue";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons-vue";

export default {
  name: "LayoutConnected",
  components: {
    TheLeftSideSection,
    TheHeaderConnected,
    CustomImageModal,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
  },
  setup() {
    let visible = ref(false);
    let isAdmin = ref(false);

    const store = useStore();
    const collapsed = ref(false);
    //const objAuth = computed(() => store.state.auth);
    const user = store.state.auth.user;
    const router = useRouter();
    const authObj = computed(() => store.state.auth);
    const isLogged = authObj.value.status.loggedIn;
    const currentRoute = router.currentRoute.value;
    const modalCustomImage = ref(null);

    if (currentRoute.meta.layout == "layout-connected" && !isLogged) {
      store.dispatch("auth/logout");
      router.push({ name: "login" });
    }

    const hide = () => {
      visible.value = false;
    };
    const openCustomImageModal = (tagIDtoLoad) => {
      modalCustomImage.value.handleShow(tagIDtoLoad);
    };
    const addAdmin = () => {
      var btoken = JSON.parse(localStorage.getItem("auth-data"));
      if (btoken && btoken.scopes) {
        var data = btoken.scopes.split(",");
        for (var i = 0; i < data.length; i++) {
          if (data[i] == "Administrador") {
            isAdmin.value = true;
          }
        }
      }
    };
    addAdmin();
    return {
      visible,
      user,
      collapsed,
      hide,
      openCustomImageModal,
      addAdmin,
      isAdmin,
      modalCustomImage,
    };
  },
};
</script>
<style scoped>
.logo {
  height: 32px;
  margin: 10px;
  width: 90%;
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme="light"] .site-layout .site-layout-background {
  background: #141414;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: var(--primary-color);
}

@media screen and (max-width: 768px) {
  .wrapper-layout {
    width: 100vw;
  }
}
</style>
