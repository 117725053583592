import AuthService from "@/services/auth-service";
import PatientService from "@/services/patient-service";
import LocalStorageService from "@/services/local-storage-service";

/* This is a JavaScript object that is being exported to the Vuex store. */
export default {
  /**
   * It calls the login method of the AuthService and returns a Promise.
   * @param model - The login model that is being passed to the login function.
   * @returns The promise is returned.
   */
  async login({ commit }, model) {
    return await AuthService.login(model)
      .then(async (token) => {
        /* This is a mutation that is being called in the JavaScript file. */
        if (token.access_token) {
          await PatientService.detail(0).then((response) => {
            var user = response.data;
            LocalStorageService.setData("user-data", user);
            commit("LOGIN_SUCCESS", { token: token, user: user });
          });
        }

        return Promise.resolve(token);
      })
      .catch((error) => {
        commit("LOGIN_FAILURE");
        return Promise.reject(error);
      });
  },

  async loginEmbebed({ commit }, model) {
    return await AuthService.loginEmbebed({ patientID: model.patientID })
      .then(async (response) => {
        if (response.tokenInfo) {
          await PatientService.detail(0).then((resp) => {
            LocalStorageService.setData("user-data", resp.data);
            LocalStorageService.setData("isEmbeddedWeb", model.isEmbeddedWeb);
            commit("LOGIN_SUCCESS", {
              token: JSON.parse(response.tokenInfo),
              user: resp.data,
              isEmbeddedWeb: model.isEmbeddedWeb,
            });
          });
        }

        return Promise.resolve(JSON.parse(response.tokenInfo));
      })
      .catch((error) => {
        commit("LOGIN_FAILURE");
        return Promise.reject(error);
      });
  },

  /**
   * It calls the login method of the AuthService and returns a Promise.
   * @param model - The login model that is being passed to the login function.
   * @returns The promise is returned.
   */
  async clientCredentials({ commit }, model) {
    return await AuthService.clientCredentials(model)
      .then(async (token) => {
        /* This is a mutation that is being called in the JavaScript file. */
        if (token.access_token) {
          await PatientService.detail(0).then((response) => {
            var user = response.data;
            LocalStorageService.setData("user-data", user);
            commit("LOGIN_SUCCESS", { token: token, user: user });
          });
          return Promise.resolve(token);
        }
      })
      .catch((error) => {
        commit("LOGIN_FAILURE");
        return Promise.reject(error);
      });
  },

  /* It calls the logout method of the AuthService */
  logout({ commit }) {
    AuthService.logout();
    LocalStorageService.clearData();
    commit("LOGOUT");
  },
  /* This is a mutation by refresh token. */
  refreshToken({ commit }, access_token) {
    commit("REFRESH_TOKEN", access_token);
  },
};
