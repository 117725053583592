const Schedule = () => import("../view/schedule.vue");
const ScheduleResume = () => import("../components/schedule-form-resume.vue");
const ScheduleConfirmResume = () =>
  import("../components/schedule-confirm.vue");
/* Exporting the object routes of schedule module. */
export default [
  {
    name: "schedule",
    path: "/schedule",
    meta: {
      Title: "Agendamiento",
      layout: "layout-connected",
      showMenu: true,
    },
    props: true,
    component: Schedule,
  },
  {
    name: "schedule-resume",
    path: "/schedule-resume",
    meta: {
      Title: "Resumen Agendamiento",
      layout: "layout-connected",
      showMenu: true,
    },
    props: true,
    component: ScheduleResume,
  },
  {
    name: "schedule-confirm",
    path: "/schedule-confirm",
    meta: {
      Title: "Confirmacion de Agendamiento",
      layout: "layout-connected",
      showMenu: true,
    },
    props: true,
    component: ScheduleConfirmResume,
  },
];
