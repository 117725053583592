import api from "./api";

/* This class is a service that gets all the affiliation types. */
class AffiliationTypeService {
  /**
   * Get all affiliation types
   * @param filter - A filter to apply to the query.
   * @returns The response is a JSON object.
   */
  get(filter) {
    return api.get("/api/affiliationtype/", filter);
  }
}
export default new AffiliationTypeService();
