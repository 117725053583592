import api from "./api";

/* This class is used to handle a service to availability of dates of schedule information. */
class ScheduleService {
  /**
   * `detail(id)` returns the datetimeavailability with the given id
   * @param id - The id of the datetimeavailability to be retrieved.
   * @returns The datetimeavailability object.
   */
  async detail(id) {
    return await api.get("/api/datetimeavailability/" + id);
  }

  /**
   * Get all the schedule availability records from the database
   * @param filter - A filter to apply to the query.
   * @returns The response is a JSON scheduleavailability object.
   */
  async get(filter) {
    return await api.get("/paciente/scheduleavailability/get", filter);
  }

  /**
   * Get all the datetime availability records from the database
   * @param filter - A filter to apply to the query.
   * @returns The response is a JSON datetimeavailability object.
   */
  async dateTime(filter) {
    return await api.get("/api/datetimeavailability/", filter);
  }

  /**
   * Get all the date availability records from the database
   * @param filter - A filter to apply to the query.
   * @returns The response is a JSON dateavailability object.
   */
  async date(filter) {
    return await api.get("/api/dateavailability/", filter);
  }
}
export default new ScheduleService();
