import api from "./api";

/* A service that provides a way to interact with the doctor API. */
class DoctorService {
  /**
   * `detail(id)` returns the doctor with the given id
   * @param id - The doctor ID.
   * @returns The insurer's details.
   */
  detail(id) {
    return api.get("/api/doctor/" + id);
  }

  /**
   * Get all info of doctor from the database
   * @param filter - The filter to apply to the query.
   * @returns The information data.
   */
  get(filter) {
    return api.get("/api/doctor/", {
      params: filter,
    });
  }

  /**
   * The function "getWaitingList" retrieves the waiting list for doctors from an API, with an optional
   * filter.
   * @param filter - The "filter" parameter is an optional parameter that can be used to filter the
   * results of the waiting list. It can be used to specify certain criteria or conditions that the
   * waiting list entries must meet in order to be included in the returned results. The specific
   * format and available options for the filter will depend
   * @returns the result of the API call to "/api/doctor/waitinglist" with the specified filter.
   */
  getWaitingList(filter) {
    return api.get("/api/doctor/waitinglist/", {
      params: filter,
    });
  }
}
export default new DoctorService();
