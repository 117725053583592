import api from "./api";

/* A service that provides a way to interact with the cancelation reaosons controller API. */
class CancelationReasonService {
  /**
   * It returns a list of cancelation reasons.
   * @param filter - A filter object that specifies the query parameters.
   * @returns The data is being returned as JSON.
   */
  get(filter) {
    return api.get("/api/cancelationReason/", filter);
  }
}
export default new CancelationReasonService();
