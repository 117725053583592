<template>
  <a-modal
    v-model:visible="visible"
    title="Administrador de Imagenes"
    centered
    :footer="null"
  >
    <a-spin :spinning="spinning" tip="Cargando..." size="large">
      <a-form
        ref="formCustomImage"
        :layout="'vertical'"
        :model="formState"
        :rules="rules"
        style="text-align: center"
      >
        <a-form-item
          name="size"
          style="width: 49%; margin-right: 2%; display: inline-block"
        >
          <div class="upload-btn-wrapper">
            <button class="btn">Subir imagen</button>
            <a-input
              class="ant-input-lg"
              type="file"
              accept="image/*"
              data-reg="\.(gif|jpg|jpeg|tiff|png|bmp)$"
              placeholder="imagen"
              aria-label="Imagen"
              v-model:value="formState.image"
              @change="upload"
            />
          </div>
        </a-form-item>
        <a-form-item
          v-if="formState.image"
          style="width: 49%; margin-right: 2%; display: inline-block"
        >
          <div class="img-container">
            <i v-if="formState.image" class="img-remove" @click="cleanFile"
              ><font-awesome-icon :icon="['far', 'trash-can']"
            /></i>
            <img id="temporalImage" src="" ref="src" />
          </div>
        </a-form-item>
        <a-form-item>
          <label id="recommendation">{{ recommendation }}</label>
        </a-form-item>
        <p class="text-center">
          <a-button
            id="registerBtn"
            type="primary"
            shape="round"
            html-type="submit"
            class="ant-btn-lg"
            @click="handleSubmit"
          >
            Actualizar
          </a-button>
        </p>
      </a-form>
    </a-spin>
  </a-modal>
  <div v-if="visible" style="display: none">
    <LoginLayout ref="layout"></LoginLayout>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import AdminValidation from "../admin-validation";
import CommonFunctions from "@/common/functions";
import LoginLayout from "@/layouts/layout-login";

export default defineComponent({
  name: "CustomTextModal",
  components: {
    LoginLayout,
  },
  setup() {
    let visible = ref(false);
    let optionsDocTypes = ref([]);
    let spinning = ref(false);
    let tagID = ref("");
    let currentInfo = ref({});
    let recommendation = ref("");
    let customImages = ref({});
    let temporalImage = ref({});

    const src = ref(null);
    const layout = ref(null);
    const apiUrl = process.env.VUE_APP_ROOT_API;
    const formCustomImage = ref();
    const store = useStore();
    customImages.value = store.state.admin.CustomImageData;
    const formState = reactive({
      image: undefined,
    });

    const rules = AdminValidation.customTextRules;

    const handleShow = async (tagIDtoLoad) => {
      cleanFile();
      tagID = tagIDtoLoad;
      if (customImages.value == undefined) {
        customImages.value = store.state.admin.CustomImageData;
      }
      currentInfo.value = customImages.value.data.find(
        (f) => f.tagID == tagIDtoLoad
      );
      var mcd = await CommonFunctions.MCD2(
        currentInfo.value.width * 1,
        currentInfo.value.height * 1
      );
      var ratioX = (currentInfo.value.width * 1) / mcd;
      var ratioY = (currentInfo.value.height * 1) / mcd;
      recommendation.value =
        "Resolución recomendada " +
        currentInfo.value.width +
        " x " +
        currentInfo.value.height +
        " o ratio " +
        ratioX +
        ":" +
        ratioY;
      visible.value = true;
    };

    const handleCancel = () => {
      visible.value = false;
    };

    const showLoading = () => {
      spinning.value = true;
    };

    const hideLoading = () => {
      spinning.value = false;
    };

    const cleanFile = () => {
      if (src.value != null) {
        src.value.src = "";
      }
      formState.image = null;
      store.dispatch("admin/clean");
    };

    const upload = (event) => {
      showLoading();
      var files = event.target.files;
      temporalImage = event.target.files;
      var model = {
        file: files[0],
        fileName: files[0].name,
        tagID: tagID,
        temporal: true,
      };
      store
        .dispatch("admin/uploadImage", model)
        .then((result) => {
          hideLoading();
          if (result) {
            src.value.src = apiUrl + result.fileName;
          } else {
            CommonFunctions.openNotificationWithIcon(
              "error",
              "CustomText",
              "No se logró cargar la imagen"
            );
          }
        })
        .catch((error) => {
          CommonFunctions.handlePromiseError(error, "modaCustomImage");
          hideLoading();
        });
    };

    const handleSubmit = async () => {
      formCustomImage.value
        .validate()
        .then(() => {
          var files = temporalImage;
          var model = {
            file: files[0],
            fileName: files[0].name,
            tagID: tagID,
            temporal: false,
          };
          showLoading();
          store.dispatch("admin/updateCustomImage", model).then((result) => {
            hideLoading();
            if (result.data) {
              layout.value.loadColorInformation();
              visible.value = false;
            } else {
              CommonFunctions.openNotificationWithIcon(
                "error",
                "CustomText",
                "No se logró actualizar el texto"
              );
            }
          });
        })
        .catch((error) => {
          CommonFunctions.handlePromiseError(error, "modaCustomText");
          hideLoading();
        });
    };

    return {
      formState,
      formCustomImage,
      rules,
      visible,
      optionsDocTypes,
      spinning,
      handleCancel,
      handleShow,
      handleSubmit,
      recommendation,
      tagID,
      upload,
      src,
      apiUrl,
      cleanFile,
      layout,
    };
  },
});
</script>
