import api from "./api";

/* A service that provides a way to interact with the Patient Waiting List API. */
class PatientWaitingListService {
  /**
   * It returns a list of Patient Waiting List.
   * @param filter - A filter object that specifies the query parameters.
   * @returns The data is being returned as JSON.
   */
  get(filter) {
    return api.get("/api/patientwaitinglist/", filter);
  }

  /**
   * It creates a new Patient Waiting List.
   * @param model - The model that will be sent to the server.
   * @returns The `post` method returns a `Promise` object.
   */
  post(model) {
    return api.post("/api/patientwaitinglist/", model);
  }

  /**
   * It updates an Patient Waiting List.
   * @param model - The model that will be sent to the server.
   * @returns The `put` method returns a `Promise` object.
   */
  async put(model) {
    return api.put("/api/patientwaitinglist/", model);
  }

  /**
   * `delete(model)` It delete an Patient Waiting List.
   * @param model - The model to delete.
   * @returns The `delete` method returns a `Promise` object.
   */
  delete(model) {
    return api.delete("/api/patientwaitinglist/", {
      data: model,
    });
  }
}
export default new PatientWaitingListService();
