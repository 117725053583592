import api from "./api";

/* This class is used to handle a patient care path service for a patient */
class PatientCarePathServiceService {
  /**
   * `detail(id)` returns the patient care path service with the given id
   * @param id - The ID of the patient care path service to retrieve.
   * @returns The patient care path with the given id.
   */
  detail(id) {
    return api.get("/api/patientcarepathservice/" + id);
  }

  /* This function is used to get all patient care path services from the server. */
  get(filter) {
    return api.get("/api/patientcarepathservice/", filter);
  }

  /**
   * It updates a patient care path service.
   * @param model - The model to be updated.
   * @returns The `put` method returns a `Promise` object.
   */
  async put(model) {
    return api.put("/api/patientcarepathservice/", model);
  }
}
export default new PatientCarePathServiceService();
