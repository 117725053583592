const Admin = () => import("../view/admin.vue");

export default [
  {
    name: "admin",
    path: "/admin",
    meta: {
      Title: "Landing",
      layout: "layout-connected",
    },
    component: Admin,
  },
];
