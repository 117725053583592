export default {
  /**
   * Get all the care path services that are scheduled
   * @param state - The state of the store.
   * @returns An array of objects.
   */
  getAppointmentScheduled(state) {
    return state.AppointmentScheduled;
  },
  /**
   * Get all the appointments that have been canceled
   * @param state - The state of the store.
   * @returns An array of care path services that have been canceled.
   */
  getAppointmentCanceled(state) {
    return state.AppointmentCanceled;
  },
  /**
   * Get all the appointments that are in the expired state
   * @param state - The state of the store.
   * @returns An array of appointments that have an expired state.
   */
  getAppointmentExpired(state) {
    return state.AppointmentExpired;
  },
  /**
   * Get the appointment detail from the state
   * @param state - The state of the store.
   * @returns The AppointmentDetail state.
   */
  getAppointmentDetail(state) {
    return state.AppointmentDetail;
  },
};
