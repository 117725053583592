import loginActions from "./store/login-actions";
import loginState from "./store/login-state";
import loginMutations from "./store/login-mutations";
import preregisterActions from "./store/user-preregister-actions";
import preregisterState from "./store/user-preregister-state";
import preregisterMutations from "./store/user-preregister-mutations";

/* This is the syntax for creating a Vuex module of authentication proccess. */
const auth = {
  namespaced: true,
  state: loginState,
  actions: loginActions,
  mutations: loginMutations,
};

/* This is the syntax for creating a Vuex module of patient preregister proccess. */
const patientpreregister = {
  namespaced: true,
  state: preregisterState,
  actions: preregisterActions,
  mutations: preregisterMutations,
};

export { auth, patientpreregister };
