import PatientService from "@/services/patient-service";
import PatientRecoverPasswordService from "../../../services/patient-recover-password-service";

/* This is a JavaScript object that is being exported to the Vuex store. */
export default {
  /**
   * This method get patient profile information.
   * The `async` keyword is used to indicate that the function is asynchronous.
   * @param model - The model that is being passed to the API.
   * @returns The data is being returned as a promise.
   */
  async getProfileInfo({ commit }, model) {
    return await PatientService.get({ params: model }).then(
      (result) => {
        /* This is a mutation that is being called in the JavaScript file. */
        if (result.data.data && result.data.data.length > 0) {
          commit("PROFILE_SUCCESS", result.data.data);
        }
        return Promise.resolve(result.data);
      },
      (error) => {
        commit("PROFILE_FAILURE");
        return Promise.reject(error);
      }
    );
  },
  /**
   * method update password on recovery process
   * It takes a model as a parameter, and returns a promise that resolves to the result of the API call,
   * or rejects with an error
   * @param model - The model that is being passed to the API.
   * @returns The result of the promise.
   */
  async updatePasswordRecovery({ commit }, model) {
    return await PatientRecoverPasswordService.put(model).then(
      (result) => {
        return Promise.resolve(result);
      },
      (error) => {
        commit("PROFILE_FAILURE");
        return Promise.reject(error);
      }
    );
  },
  /* This method clean patient profile data. */
  clean({ commit }) {
    commit("PROFILE_CLEAN");
  },
};
