import processActions from "./store/process-actions";
import processMutations from "./store/process-mutations";
import processState from "./store/process-state";
import scheduleActions from "./store/schedule-actions";
import scheduleMutations from "./store/schedule-mutations";
import scheduleState from "./store/schedule-state";
import scheduleGetters from "./store/schedule-getters";

/* This is the syntax for creating a Vuex module for patient process. */
const patientProcess = {
  namespaced: true,
  state: processState,
  actions: processActions,
  mutations: processMutations,
};

/* This is the syntax for creating a Vuex module for patient schedule available. */
const patientSchedule = {
  namespaced: true,
  state: scheduleState,
  actions: scheduleActions,
  mutations: scheduleMutations,
  getters: scheduleGetters,
};

export { patientProcess, patientSchedule };
