/* Exporting external process mutations. */
export default {
  /**
   * It's a function that takes in a state and a processData, and then sets the state's processData to
   * the processData's info, and the state's process to the processData's name.
   * @param state - The state object
   * @param processData - This is the data that is returned from the API.
   */
  EXTERNAL_SUCCESS(state, processData) {
    state.processData = processData.info;
    state.process = processData.name;
  },
  /**
   * It sets the processData and process properties of the state object to empty objects and strings
   * respectively.
   * @param state - The state object
   */
  EXTERNAL_FAILURE(state) {
    state.processData = {};
    state.process = "";
  },
  /* Cleaning the state. */
  EXTERNAL_CLEAN(state) {
    state.processData = {};
    state.process = "";
  },
};
