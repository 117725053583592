import LocalStorageService from "@/services/local-storage-service";

/* Checking if the user is logged in or not. */
const authData = LocalStorageService.getData("auth-data");
const userData = LocalStorageService.getData("user-data");
const isEmbeddedWeb = LocalStorageService.getData("isEmbeddedWeb");

/* Checking if the user is logged in or not. load info form local storage */
const initialState = authData
  ? {
      status: { loggedIn: true },
      token: authData.access_token,
      user: userData,
      isEmbeddedWeb: isEmbeddedWeb,
    }
  : {
      status: { loggedIn: false },
      token: null,
      user: null,
      isEmbeddedWeb: null,
    };

export default {
  ...initialState,
};
