import patientCarePathActions from "./store/patient-care-path-actions";
import patientCarePathState from "./store/patient-care-path-state";
import patientCarePathMutations from "./store/patient-care-path-mutations";
import patientCarePathServiceActions from "./store/patient-care-path-service-actions";
import patientCarePathServiceState from "./store/patient-care-path-service-state";
import patientCarePathServiceMutations from "./store/patient-care-path-service-mutations";
import patientCarePathServiceGetters from "./store/patient-care-path-service-getters";
import patientAppointmentActions from "./store/patient-appointment-actions";
import patientAppointmentState from "./store/patient-appointment-state";
import patientAppointmentMutations from "./store/patient-appointment-mutations";
import patientAppointmentGetters from "./store/patient-appointment-getters";

/* This is the syntax for creating a Vuex module of patient care path. */
const patientCarePath = {
  namespaced: true,
  state: patientCarePathState,
  actions: patientCarePathActions,
  mutations: patientCarePathMutations,
};

/* Creating a Vuex module of patient care path service. */
const patientCarePathService = {
  namespaced: true,
  state: patientCarePathServiceState,
  actions: patientCarePathServiceActions,
  mutations: patientCarePathServiceMutations,
  getters: patientCarePathServiceGetters,
};

/* Creating a Vuex module of patient appointment. */
const patientAppointment = {
  namespaced: true,
  state: patientAppointmentState,
  actions: patientAppointmentActions,
  mutations: patientAppointmentMutations,
  getters: patientAppointmentGetters,
};

export { patientCarePath, patientCarePathService, patientAppointment };
