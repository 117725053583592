import api from "./api";

/* A service that provides a way to interact with the link notification API. */
class LinkNotificationService {
  /**
   * Get info to link notification process by guidid from the database
   * @param filter - The filter to apply to the query.
   * @returns The information data.
   */
  get(filter) {
    return api.get("/api/linknotification?guidToken=" + filter);
  }
}
export default new LinkNotificationService();
