import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import authInterceptors from "./services/auth-interceptor";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(far, fas, fab);
authInterceptors(store);

const app = createApp(App)
  .use(router)
  .use(store)
  .use(Antd)
  .use(VueAxios, axios);
app.provide("axios", app.config.globalProperties.axios);
app.component("font-awesome-icon", FontAwesomeIcon).mount("#app");
