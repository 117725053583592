const External = () => import("../view/external.vue");
/* Exporting the object routes of external actions module. */
export default {
  name: "external",
  path: "/linkredirect/:keyRedirect",
  meta: {
    Title: "Accion Externa",
    layout: "layout-not-connected",
  },
  props: true,
  component: External,
};
