import api from "./api";

/* This class is used to handle a service conditions to process of schedule */
class ConditionService {
  /* This function is used to get all service conditions from the server. */
  async get(filter) {
    return await api.get("/api/condition/", filter);
  }
}
export default new ConditionService();
