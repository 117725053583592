import api from "./api";

/* A service that provides a way to interact with the Term Policy API. */
class TermPolicyService {
  /**
   * Get all term policy
   * @param filter - A filter to apply to the query.
   * @returns The response is a JSON object.
   */
  get(filter) {
    return api.get("/api/termpolicy/", { params: { ...filter } });
  }
}
export default new TermPolicyService();
