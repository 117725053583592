import api from "./api";

/* A service that provides a way to interact with the User Validations API. */
class UserValidationsService {
  /**
   * `get(id)` returns if the patient has a valid user
   * @param id - The patient ID.
   * @returns A boolean.
   */
  async get(filter) {
    return await api.get("/api/uservalidations/", filter);
  }
}
export default new UserValidationsService();
