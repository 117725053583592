/* This is a JavaScript object that is being exported to the Vuex store. */
export default {
  /**
   * When the showLoading function is called, it will commit the LOADING_PROCESS mutation.
   * this mutation enable the layer with spinner loading in the main layout
   */
  showLoading({ commit }) {
    commit("LOADING_PROCESS");
  },

  /**
   * When the loading is finished, commit the LOADING_FINISH mutation.
   * this mutation disable the layer with spinner loading in the main layout
   */
  hideLoading({ commit }) {
    commit("LOADING_FINISH");
  },
};
