/* Exporting login mutations. */
export default {
  /**
   * * Set the loggedIn property to true and set the token property to the token passed in
   * @param state - The state object that is being updated.
   * @param token - The token that was returned by the login API.
   */
  LOGIN_SUCCESS(state, data) {
    state.status.loggedIn = true;
    state.token = data.token;
    state.user = data.user;
    if (data.isEmbeddedWeb != undefined && data.isEmbeddedWeb != null) {
      state.isEmbeddedWeb = data.isEmbeddedWeb;
    }
  },
  /**
   * *Log in failure*
   *
   * # <a id='loginSuccess'></a>
   * # loginSuccess
   * #
   * #
   * @param state - The state object that is being updated.
   */
  LOGIN_FAILURE(state) {
    state.status.loggedIn = false;
    state.token = null;
    state.user = null;
    state.isEmbeddedWeb = null;
  },
  /* Setting the loggedIn property to false and setting the token property to null. */
  LOGOUT(state) {
    state.status.loggedIn = false;
    state.token = null;
    state.user = null;
    state.isEmbeddedWeb = null;
  },
  /* This is a mutation that is used to update the token property of the state object. */
  REFRESH_TOKEN(state, access_token) {
    state.status.loggedIn = true;
    state.token = { ...state.token, access_token: access_token };
  },
};
