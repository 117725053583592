const Login = () => import("../view/login.vue");
const UserPreRegister = () =>
  import("../components/login-modal-preregister.vue");
const UserRegistry = () => import("../components/user-register.vue");

/* Exporting the object routes of login module. */
export default [
  {
    name: "login",
    path: "/login",
    meta: {
      Title: "Ingreso",
      layout: "layout-login",
    },
    component: Login,
  },
  {
    name: "home",
    path: "/",
    meta: {
      Title: "Principal",
      layout: "layout-login",
    },
    component: Login,
  },
  {
    name: "preregister",
    path: "/user-preregister",
    meta: {
      Title: "Principal",
      layout: "layout-login",
    },
    component: UserPreRegister,
  },
  {
    name: "registry",
    path: "/user-registry",
    meta: {
      Title: "Principal",
      layout: "layout-not-connected",
    },
    component: UserRegistry,
  },
];
