<template>
  <a-spin :spinning="spinning" tip="Cargando..." size="large">
    <component :is="layout">
      <router-view />
    </component>
  </a-spin>
</template>
<script>
const defaultLayout = "layout-login";
import { ref, computed, onBeforeMount, onMounted, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import LayoutConnected from "./layouts/layout-connected.vue";
import LayoutNotConnected from "./layouts/layout-not-connected.vue";
import LayoutLogin from "./layouts/layout-login.vue";
import EventBus from "./common/helpers/event-bus";
import { hubConnection } from "signalr-asp-net";
import CommonFunctions from "@/common/functions";

export let route = useRouter();

export default {
  components: {
    LayoutLogin,
    LayoutConnected,
    LayoutNotConnected,
  },
  setup() {
    let content = ref("");
    const store = useStore();
    const authObj = computed(() => store.state.auth);
    const pageObj = computed(() => store.state.page);
    const avaliableSchedule = computed(() => store.state.patientSchedule);
    const currentSession = authObj.value.token;
    const isLogged = authObj.value.status.loggedIn;
    let inProcess = null;
    route = useRouter();
    const currentRoute = route.currentRoute.value;
    const guid = computed(() => {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    });
    const loadColorInformation = async () => {
      //*Cargar Colores
      content.value = await CommonFunctions.getColorInformation();
      renderDesign(content);
    };

    function renderDesign(json) {
      var cssFile = "";

      if (json.value) {
        /*console.log(
          "🚀 ~ file: App.vue ~ line 58 ~ renderDesign ~ json.value",
          json.value
        );*/
        var colors = json.value;
        var primaryColor = colors.find(
          (e) => e.variableID === "PrincipalColor"
        );
        var primaryColorHover = colors.find(
          (e) => e.variableID === "PrincipalColorHover"
        );
        var primaryColorSelected = colors.find(
          (e) => e.variableID === "PrincipalColorSelected"
        );
        var primaryColorDisabled = colors.find(
          (e) => e.variableID === "PrincipalColorDisabled"
        );
        var secondaryColor = colors.find(
          (e) => e.variableID === "SecondaryColor"
        );
        var secondaryColorHover = colors.find(
          (e) => e.variableID === "SecondaryColorHover"
        );
        var secondaryColorDisabled = colors.find(
          (e) => e.variableID === "SecondaryColorDisabled"
        );

        cssFile =
          cssFile +
          ":root {" +
          "--primary-color: " +
          primaryColor.value +
          ";" +
          "--primary-color-hover: " +
          primaryColorHover.value +
          ";" +
          "--primary-selected: " +
          primaryColorSelected.value +
          ";" +
          "--disabled-color: " +
          primaryColorDisabled.value +
          ";" +
          "--secondary-color: " +
          secondaryColor.value +
          ";" +
          "--secondary-color-hover: " +
          secondaryColorHover.value +
          ";" +
          "--secondary-disabled-color: " +
          secondaryColorDisabled.value +
          ";}";
      }

      var blob = new Blob([cssFile], { type: "text/css" });

      var link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = window.URL.createObjectURL(blob);
      link.id = "customColors";
      document.body.appendChild(link);
    }

    let faviconIcon = "favicon.ico";
    let spinning = computed(() => {
      return pageObj.value.loading;
    });
    let isExternalProcess = ref(false);

    var urlBase = window.location.pathname.replace("//", "/");
    var code = urlBase.split("/").pop();

    CommonFunctions.loadSystemVariable("Favicon").then((result) => {
      if (result && result.value) {
        faviconIcon = result.value;
        favicon.href = `${process.env.VUE_APP_ROOT_API}/Resources/Images/Logo/${faviconIcon}?${guid.value}`;
        favicon.type = "image/png";
      }
    });

    document.title = process.env.VUE_APP_CLIENT_NAME;

    const favicon = document.getElementById("favicon");

    if (urlBase.indexOf("/linkredirect/") == 0) {
      isExternalProcess.value = true;
    }

    const layout = computed(() => {
      return route.currentRoute.value.meta.layout || defaultLayout;
    });

    /**
     * this method reload data of schedule of times or closest
     */
    const allowedScheduleTimeData = async (date, type) => {
      var filter = {
        size: 1,
      };

      if (avaliableSchedule.value.filterAvailableSchedule) {
        filter = {
          available: true,
          hiddenServiceGroups: false,
          planID: avaliableSchedule.value.filterAvailableSchedule.planID,
          serviceID: avaliableSchedule.value.filterAvailableSchedule.serviceID,
          date: date,
        };
        if (
          Object.prototype.hasOwnProperty.call(
            avaliableSchedule.value.filterAvailableSchedule,
            "entityID"
          )
        ) {
          filter.entityID =
            avaliableSchedule.value.filterAvailableSchedule.entityID;
        }
        if (
          Object.prototype.hasOwnProperty.call(
            avaliableSchedule.value.filterAvailableSchedule,
            "doctorID"
          )
        ) {
          filter.doctorID =
            avaliableSchedule.value.filterAvailableSchedule.doctorID;
        }
      }
      if (Object.prototype.hasOwnProperty.call(filter, "date")) {
        var stateName = "";
        switch (type) {
          case "hours":
            delete filter.size;
            stateName = "loadScheduleTimeInformation";
            break;
          case "closest":
            filter.size = 3;
            stateName = "loadScheduleClosestTimeInformation";
            break;
        }

        if (inProcess != date) {
          inProcess = date;
          await store
            .dispatch(`patientSchedule/${stateName}`, {
              params: filter,
            })
            .then((result) => {
              inProcess = null;
              if (result.data) {
                return true;
              }
            })
            .catch((error) => {
              CommonFunctions.handlePromiseError(error, "updateSignalR");
            });
        }
      }
    };

    onBeforeMount(() => {
      let isExternalProcess = ref(false);

      if (urlBase.indexOf("/linkredirect/") == 0) {
        isExternalProcess.value = true;
      }

      if (!isExternalProcess.value) {
        /**
         * Configuration of signalR
         */
        const signalrConnection = hubConnection(process.env.VUE_APP_ROOT_API);
        const hubProxy = signalrConnection.createHubProxy("signalrhub");

        hubProxy.on("sendappointment", function (doctorID, date, time) {
          var mustUpdateTime =
            store.getters["patientSchedule/mustUpdateTimeInformation"](date);
          // if mustUpdateTime is true- reload information of shedule hour availibles for a date
          if (mustUpdateTime) {
            store.dispatch("patientSchedule/wasUpdatedTimeSchedule", {
              state: true,
              dateTime: `${date}T${time}`,
            });
            allowedScheduleTimeData(date, "hours");
          }

          // if mustUpdateClosest is true- reload information of shedule closest
          var mustUpdateClosest =
            store.getters["patientSchedule/mustUpdateClosestInformation"](date);

          if (mustUpdateClosest) {
            allowedScheduleTimeData(date, "closest");
          }
        });

        // signalr connection by id
        signalrConnection
          .start({ jsonp: true, transport: ["webSockets", "serverSentEvents"] })
          .done(function () {
            console.log("Now connected, connection ID=" + signalrConnection.id);
          })
          .fail(function () {
            console.log("Could not connect");
          });
      }

      if (
        (currentRoute.meta.layout == defaultLayout ||
          currentRoute.path == "/") &&
        isLogged &&
        !isExternalProcess.value
      ) {
        route.push({ name: "carepath" });
      }
      if (currentRoute.meta.layout == "layout-connected" && !isLogged) {
        store.dispatch("auth/logout");
        route.push({ name: "login" });
      }
      if (isExternalProcess.value) {
        route.push({
          name: "external",
          params: {
            keyRedirect: code,
          },
        });
      }
    });

    onMounted(() => {
      EventBus.on("logout", () => {
        store.dispatch("auth/logout");
      });
    });

    onBeforeUnmount(() => {
      EventBus.remove("logout");
    });

    loadColorInformation();

    return {
      currentSession,
      layout,
      isLogged,
      spinning,
      route,
      content,
      loadColorInformation,
    };
  },
  watch: {
    currentSession(value, oldValue) {
      if (value == "") {
        console.log(oldValue);
        route.push({ name: "login" });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/style/index.scss";
</style>
<style>
@import "@/assets/style/ant-notification.css";
</style>
