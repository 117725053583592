import api from "./api";

class AppointmentExtraDataService {
  get(filter) {
    return api.get("/api/appointmentextradata", {
      params: filter,
    });
  }

  getRecommended(filter) {
    return api.get("/api/extradata/recommended", {
      params: filter,
    });
  }
}

export default new AppointmentExtraDataService();
