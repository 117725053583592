import api from "./api";

/* A service that provides a way to interact with the doctor specialty API. */
class DoctorSpecialtyService {
  /**
   * `detail(id)` returns the doctor specialty with the given id
   * @param id - The doctor ID.
   * @returns The insurer's details.
   */
  detail(id) {
    return api.get("/api/doctorspecialty/" + id);
  }

  /**
   * Get all info of doctor specialty from the database
   * @param filter - The filter to apply to the query.
   * @returns The information data.
   */
  get(filter) {
    return api.get("/api/doctorspecialty/", {
      params: filter,
    });
  }
}
export default new DoctorSpecialtyService();
