import ScheduleService from "@/services/schedule-service";

/* This is a JavaScript object that is being exported to the Vuex store. */
export default {
  /**
   * It loads the schedule dates information from the server.
   * @param filter - The filter object that will be used to query the API.
   * @returns The promise is being returned.
   */
  async loadScheduleInformation({ commit }, filter) {
    return await ScheduleService.date(filter)
      .then(async (response) => {
        commit("SCHEDULE_SUCCESS", response.data.data);
        return Promise.resolve(response);
      })
      .catch((error) => {
        commit("SCHEDULE_FAILURE");
        return Promise.reject(error);
      });
  },
  /**
   * It loads the schedule hours information from the server by a specific date.
   * @param filter - The filter object that is used to filter the data.
   * @returns The promise is being returned.
   */
  async loadScheduleTimeInformation({ commit }, filter) {
    if (filter) {
      return await ScheduleService.dateTime(filter)
        .then(async (response) => {
          commit("SCHEDULETIME_SUCCESS", response.data.data);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("SCHEDULETIME_FAILURE");
          return Promise.reject(error);
        });
    } else {
      return Promise.reject("sin filtro");
    }
  },
  /**
   * It loads the schedule hours information from the server by a specific date.
   * @param filter - The filter object that is used to filter the data.
   * @returns The promise is being returned.
   */
  async loadScheduleClosestTimeInformation({ commit }, filter) {
    return await ScheduleService.dateTime(filter)
      .then(async (response) => {
        commit("SCHEDULECLOSEST_SUCCESS", response.data.data);

        return Promise.resolve(response);
      })
      .catch((error) => {
        commit("SCHEDULECLOSEST_FAILURE");
        return Promise.reject(error);
      });
  },
  /* A function that is being called from the component. It is used to update the state. */
  async loadDataFilter({ commit }, filter) {
    commit("SCHEDULEFILTER_SUCCESS", filter);
  },
  /* A function that is being called from the component. It is used to update the state. */
  async cleanDataFilter({ commit }, filter) {
    commit("SCHEDULEFILTER_CLEAN", filter);
  },
  /**
   * This function is used to clean the schedule closest.
   */
  async cleanScheduleClosest({ commit }) {
    commit("SCHEDULECLOSEST_FAILURE");
  },
  /**
   * This function is used to clean the schedule dates.
   */
  async cleanScheduleDates({ commit }) {
    commit("SCHEDULE_CLEAN");
  },
  /**
   * This function is used to update information of schedule selected.
   */
  async wasUpdatedTimeSchedule({ commit }, value) {
    commit("SCHEDULETIME_UPDATE", value.state);
    commit("SCHEDULEDATETIME_UPDATE", value.dateTime);
  },

  /* The `cleanScheduleTimes` function is used to clean the schedule times in the Vuex store. It
  commits the "SCHEDULE_TIME_CLEAN" mutation, which will update the state and remove any
  schedule time information that was previously loaded. */
  async cleanScheduleTimes({ commit }) {
    commit("SCHEDULE_TIME_CLEAN");
  },
};
