import api from "./api";

/* A service that provides a way to interact with the Social class API. */
class SocialClassService {
  /**
   * Get all social class
   * @param filter - A string that is used to filter the results.
   * @returns The data is being returned as a JSON object.
   */
  get(filter) {
    return api.get("/api/socialclass/", filter);
  }
}
export default new SocialClassService();
