<template>
  <div>
    <a-row type="flex" justify="center" :gutter="20">
      <a-col :span="16">
        <div class="card-container">
          <a-button type="link" @click="handleBack">
            <LeftCircleTwoTone /> Atrás
          </a-button>
          <router-view />
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { ref } from "vue";
import CommonFunctions from "../common/functions";
import { LeftCircleTwoTone } from "@ant-design/icons-vue";
export default {
  name: "LayoutNotConnected",
  components: {
    LeftCircleTwoTone,
  },
  setup() {
    let content = ref("");

    const loadColorInformation = async () => {
      content.value = await CommonFunctions.getColorInformation();
    };
    loadColorInformation();
    const handleBack = () => {
      history.back(-1);
    };
    return {
      content,
      handleBack,
    };
  },
  mounted() {},
};
</script>
<style></style>
