import PatientCarePathService from "@/services/patient-care-path-service";
import InsurerPlanService from "@/services/insurer-plan-service";
import InsurerService from "@/services/insurer-service";
import PatientDoctorRelatedService from "@/services/patient-doctor-related-service";

/* This is a JavaScript object that is being exported to the Vuex store. */
export default {
  /**
   * This method get patient care path.
   * The `async` keyword is used to indicate that the function is asynchronous.
   * @param model - The model that is being passed to the API.
   * @returns The data is being returned as a promise.
   */
  async getPatientCarePath({ commit }, model) {
    return await PatientCarePathService.get({ params: model }).then(
      (result) => {
        /* This is a mutation that is being called in the JavaScript file. */
        if (result.data.data && result.data.data.length > 0) {
          commit("CAREPATH_SUCCESS", result.data.data);
        }
        return Promise.resolve(result.data.data);
      },
      (error) => {
        commit("CAREPATH_FAILURE");
        return Promise.reject(error);
      }
    );
  },
  /**
   * This function is used to load the plan information.
   * @param filter - The filter object that will be used to query the API.
   * @returns The promise is being returned.
   */
  async loadPlanInformation({ commit }, filter) {
    return await InsurerPlanService.detail(filter)
      .then(async (response) => {
        commit("PLAN_SUCCESS", response.data);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        commit("PLAN_FAILURE");
        return Promise.reject(error);
      });
  },
  /**
   * This function calls the API to get the insurer information.
   * @param filter - The filter object that will be used to query the API.
   * @returns The promise is being returned.
   */
  async loadInsurerInformation({ commit }, filter) {
    return await InsurerService.detail(filter)
      .then(async (response) => {
        commit("INSURER_SUCCESS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((error) => {
        commit("INSURER_FAILURE");
        return Promise.reject(error);
      });
  },
  /* This method clean patient care path data. */
  clean({ commit }) {
    commit("CAREPATH_CLEAN");
  },
  async loadMultiService({ commit }, filter) {
    return await PatientCarePathService.checkMultiService(filter)
      .then(async (response) => {
        commit("PLAN_MULTISERVICE_SUCCESS", response.data);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        commit("PLAN_MULTISERVICE_FAILURE");
        return Promise.reject(error);
      });
  },

  /**
   * This function loads the doctor related to a specific patient service ID.
   * @param serviceID - The `serviceID` parameter is the ID of the service that is related to the
   * patient. It is used to fetch the doctor related to that service.
   * @returns a promise.
   */
  async loadPatientRelatedDoctor({ commit }, serviceID) {
    return await PatientDoctorRelatedService.getDoctor(serviceID)
      .then(async (response) => {
        commit("PATIENT_RELATED_DOCTOR_SUCCESS", response.data);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        commit("PATIENT_RELATED_DOCTOR_FAILURE");
        return Promise.reject(error);
      });
  },

  /**
   * The function "loadRestrictionEntity" commits a success mutation with the given entity ID.
   * @param entityID - The entityID parameter is the unique identifier of the entity that needs to be
   * loaded.
   */
  loadRestrictionEntity({ commit }, entityID) {
    commit("RESTRICTION_ENTITY_SUCCESS", entityID);
  },
};
