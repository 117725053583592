/* Exporting care path mutations. */
export default {
  /**
   * If the state has a care path, then the care path is equal to the care path data from patient care path API.
   * @param state - The state object
   * @param carePath - The care path object that is returned from the API.
   */
  CAREPATH_SUCCESS(state, carePath) {
    state.hasCarePath = true;
    state.CarePath = carePath;
  },
  /**
   * If care path API response failure, set the care path to null, the insurer to null, and the plan to
   * null.
   * @param state - The state object
   */
  CAREPATH_FAILURE(state) {
    state.hasCarePath = false;
    state.CarePath = null;
    state.Insurer = null;
    state.Plan = null;
  },
  /**
   * Cleaning the state of care path.
   */
  CAREPATH_CLEAN(state) {
    state.hasCarePath = false;
    state.CarePath = null;
    state.Insurer = null;
    state.Plan = null;
  },
  /**
   * The INSURER_SUCCESS mutation. The state parameter is the state object,
   * and the insurerData parameter is the respose of Insurer API in action.
   * then sets the state.Insurer property to insurerData parameter.
   * @param state - The state object
   * @param insurerData - This is the data that is returned from the API.
   */
  INSURER_SUCCESS(state, insurerData) {
    state.Insurer = insurerData;
  },
  /* If insurer API response failure, Setting the state.Insurer property to null. */
  INSURER_FAILURE(state) {
    state.Insurer = null;
  },
  /**
   * The PLAN_SUCCESS mutation, state and planData,
   * and the planData parameter is the respose of Plan API in action
   * then sets the state.Plan to planData parameter.
   * @param state - This is the state object that we are going to be modifying.
   * @param planData - This is the data that is returned from the API.
   */
  PLAN_SUCCESS(state, planData) {
    state.Plan = planData;
  },
  /* If Plan API response failure, Setting the state.Plan property to null. */
  PLAN_FAILURE(state) {
    state.Plan = null;
  },
  PLAN_MULTISERVICE_SUCCESS(state, data) {
    state.isMultiService = data.isMultiService;
    state.serviceIDs = data.serviceIDs;
    state.scheduleTime = data.scheduleTime;
    state.phase = data.phases;
    state.extraData = data.extraData;
    state.services = data.services;
  },
  /* If Plan API response failure, Setting the state.Plan property to null. */
  PLAN_MULTISERVICE_FAILURE(state) {
    state.isMultiService = false;
    state.serviceIDs = null;
    state.scheduleTime = null;
    state.phase = null;
    state.extraData = null;
    state.services = null;
  },

  PATIENT_RELATED_DOCTOR_SUCCESS(state, data) {
    if (data.doctorID > 0) {
      state.restrictionUserID = data.doctorID;
    } else {
      state.restrictionUserID = null;
    }
  },

  PATIENT_RELATED_DOCTOR_FAILURE(state) {
    state.restrictionUserID = null;
  },

  RESTRICTION_ENTITY_SUCCESS(state, data) {
    state.restrictionEntityID = data;
  },
};
