<template>
  <div>
    <div class="landing-bg-container">
      <a-row
        type="flex"
        justify="center"
        :gutter="20"
        style="min-height: 100vh"
      >
        <a-col :xs="0" :sm="0" :md="2" :lg="3" :xl="3" :xxl="3"> </a-col>
        <a-col :xs="24" :sm="24" :md="13" :lg="11" :xl="11" :xxl="11">
          <div class="home-text vertical-center">
            <div class="logo-container home-logo"></div>
            <!-- <img class="home-logo" src="@/assets/img/medicarte-logo.svg" /> -->
            <h1 class="intro-tittle" v-html="customIntroTittle.text"></h1>
            <p class="intro-subtittle" v-html="customIntroSubTittle.text"></p>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7" :xxl="7">
          <router-view />
        </a-col>
        <a-col :xs="0" :sm="0" :md="2" :lg="3" :xl="3" :xxl="3"> </a-col>
      </a-row>
    </div>
    <div class="about-text">
      <a-row type="flex" justify="center" :gutter="20">
        <a-col
          :xs="24"
          :sm="24"
          :md="11"
          :lg="11"
          :xl="11"
          :xxl="11"
          class="home-text"
        >
          <h1 class="about-us-tittle" v-html="aboutUsTittle.text"></h1>
          <p class="about-us-subtittle" v-html="aboutUsSubTittle.text"></p>
        </a-col>
        <a-col
          :xs="24"
          :sm="24"
          :md="7"
          :lg="7"
          :xl="7"
          :xxl="7"
          class="text-center"
        >
          <div class="logo-container home-logo"></div>
          <!-- <img class="home-logo" src="../assets/img/medicarte-logo.svg" /> -->
        </a-col>
      </a-row>
    </div>
    <footer>
      <a-row type="flex" justify="center" :gutter="20">
        <a-col :xs="24" :sm="24" :md="11" :lg="11" :xl="11" :xxl="11">
          <p class="copy-r">
            Todos los derechos reservados © 2020 dondoctor -
            <a v-if="adminAccess" @click="handleRedirect">{{
              adminAccessText
            }}</a>
          </p>
        </a-col>
        <a-col
          :xs="24"
          :sm="24"
          :md="7"
          :lg="7"
          :xl="7"
          :xxl="7"
          class="text-center"
        >
          <p class="copy-dondoctor">
            Creado y diseñado por
            <img
              width="80px"
              height="auto"
              src="../assets/img/dondoctor-logo.svg"
            />
          </p>
        </a-col>
      </a-row>
    </footer>
    <!--<RegisterModal ref="modal" />-->
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import CommonFunctions from "../common/functions";

export default {
  name: "LayoutLogin",
  setup() {
    let content = ref("");
    let customTexts = [];
    let customImages = [];
    let customIntroTittle = ref({});
    let customIntroSubTittle = ref({});
    let aboutUsTittle = ref({});
    let aboutUsSubTittle = ref({});
    let imagePath = ref({});
    let adminAccess = ref(false);
    let adminAccessText = ref("Ingreso funcionarios");
    const store = useStore();
    const apiUrl = process.env.VUE_APP_ROOT_API;

    const loadAdminAccessInfo = async () => {
      const response = await CommonFunctions.loadSystemVariable(
        "EnableAccessToOfficial"
      );

      adminAccess.value = response?.value?.toLowerCase() === "true";
      if (adminAccess.value) {
        const text = await CommonFunctions.loadSystemVariable(
          "AccessToOfficialText"
        );

        if (text.value != null && text.value != "") {
          adminAccessText.value = text?.value;
        }
      }
    };
    loadAdminAccessInfo();

    const loadColorInformation = async () => {
      imagePath = await CommonFunctions.getImagePath();
      //*Cargar Colores
      content.value = await CommonFunctions.getColorInformation();
      //*Cargar textos
      customTexts = await CommonFunctions.getCustomTexts();
      //*Cargar imagenes
      customImages = await CommonFunctions.getCustomImages();

      customIntroTittle.value = customTexts.data.find(
        (f) => f.tagID == "intro-tittle"
      );
      customIntroSubTittle.value = customTexts.data.find(
        (f) => f.tagID == "intro-subtittle"
      );
      aboutUsTittle.value = customTexts.data.find(
        (f) => f.tagID == "about-us-tittle"
      );
      aboutUsSubTittle.value = customTexts.data.find(
        (f) => f.tagID == "about-us-subtittle"
      );
      await store.dispatch("admin/loadCustomTextInformation", customTexts);
      await store.dispatch("admin/loadCustomImageInformation", customImages);
      //*Crear css con las caracteristicas personalizadas
      var element = document.getElementById("customStyles");
      if (element != null) {
        document.body.removeChild(element);
      }
      var cssFile = "";
      for (var i = 0; i < customTexts.data.length; i++) {
        cssFile =
          cssFile +
          "." +
          customTexts.data[i].tagID +
          "{ font-size:" +
          customTexts.data[i].size +
          "px; " +
          "color:" +
          customTexts.data[i].color +
          "; }";
      }

      if (customImages) {
        for (var a = 0; a < customImages.data.length; a++) {
          var styles = customImages.data[a].styles.replace(
            "ImagePath",
            apiUrl +
              imagePath.value +
              customImages.data[a].tagID +
              "/" +
              customImages.data[a].fileName
          );
          styles = styles.replace(
            "heightVal",
            customImages.data[a].height + "px"
          );
          styles = styles.replace(
            "maxwidthval",
            customImages.data[a].width + "px"
          );

          var fatherClass = customImages.data[a].tagID.split(".");

          if (fatherClass.length > 1) {
            cssFile = cssFile + "." + fatherClass[0];
            cssFile = cssFile + " ." + fatherClass[1] + " {" + styles + "}";
          } else {
            cssFile =
              cssFile + "." + customImages.data[a].tagID + "{" + styles + "}";
          }
        }
      }

      var blob = new Blob([cssFile], { type: "text/css" });

      var link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = window.URL.createObjectURL(blob);
      link.id = "customStyles";
      document.body.appendChild(link);
    };
    loadColorInformation();

    const handleRedirect = () => {
      window.open(process.env.VUE_APP_FUNCTIONARY_URL, "_blank");
    };

    return {
      content,
      handleRedirect,
      customIntroTittle,
      customIntroSubTittle,
      aboutUsTittle,
      aboutUsSubTittle,
      customImages,
      apiUrl,
      loadColorInformation,
      adminAccess,
      adminAccessText,
    };
  },
  mounted() {},
};
</script>
<style></style>
